import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/store";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { fetchServiceTypes } from "../../redux/reducers/serviceTypeSlice";
import { toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import Calendar from "../../components/Calendar/Calendar";
import { api } from "../../api";
import Loading from "../resuseables/Loading";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

interface Entry {
    styleId: number;
    serviceName: string;
    style: string;
    styleShortDescription: string;
    styleFullDetailsOfService: string;
    stylePrice: string;
    styleImage: File | null;
    styleDuration: string;
    styleDeposit: string | number;
    discount: number;
    value: number;
    availableDays: string[];
    availableTimes: null;
    createdAt: string;
}

interface UpdateFormProps {
    style: Entry;
    onUpdate: (styleId: number, updatedStyleData: FormData) => Promise<void>;
    onCancel: () => void;
}

const UpdateServiceInfoMain: React.FC<UpdateFormProps> = ({
    style,
    onUpdate,
    onCancel,
}) => {

    const navigate = useNavigate()
    const token = useSelector((state: RootState) => state.user.userData?.token);
    const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
    const services = useSelector((state: RootState) => state.serviceTypes.services);

    const [selectedService, setSelectedService] = useState(style?.styleId || 0);
    const [selectedServiceName, setSelectedServiceName] = useState(style?.serviceName || "");
    const [styles, setStyles] = useState(style?.style || "");
    const [deposit, setDeposit] = useState(style?.styleDeposit?.toString() || "");
    const [servicePrice, setServicePrice] = useState(style?.stylePrice || "");
    const [discount, setDiscount] = useState(style?.discount?.toString() || "");
    const [discountedPrice, setDiscountedPrice] = useState(style?.value?.toString() || "");
    const [shortDescription, setShortDescription] = useState(style?.styleShortDescription || "");
    const [fullDetails, setFullDetails] = useState(style?.styleFullDetailsOfService || "");
    const [styleDuration, setStyleDuration] = useState('1:00');
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [selectedDates, setSelectedDates] = useState<string[]>(style?.availableDays || []);
    const [selectedTimes, setSelectedTimes] = useState<string[]>(style?.availableTimes || []);
    const [selectedHour, setSelectedHour] = useState("00");
    const [selectedMinute, setSelectedMinute] = useState("00");
    const [isLoading, setIsLoading] = useState(false);
    const [existingImageUrl, setExistingImageUrl] = useState<string>("");


    console.log("FormData", style)

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
        },
        onDrop: (acceptedFiles) => {
            setUploadedFile(acceptedFiles[0]);
        },
    });

    useEffect(() => {
        dispatch(fetchServiceTypes());
    }, [dispatch]);

    const handleDateChange = (date: Date | Date[]) => {
        if (Array.isArray(date)) {
            const filteredDates = date.filter((d) => d >= new Date());
            const formattedDates = filteredDates.map((d) => format(d, "yyyy-MM-dd"));
            setSelectedDates(formattedDates);
        } else {
            if (date < new Date()) {
                toast.error("Cannot select previous dates");
            } else {
                setSelectedDates([format(date, "yyyy-MM-dd")]);
            }
        }
    };

    const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);

    const updateAvailableTimeSlots = (duration: string) => {
        const [hours, minutes] = duration.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) return;

        const durationInMinutes = hours * 60 + minutes;
        const slots = [];
        let currentTime = new Date(2023, 0, 1, 8, 0); // Start at 8 AM
        const endTime = new Date(2023, 0, 1, 19, 0);  // End at 7 PM

        while (currentTime < endTime) {
            slots.push(currentTime.toTimeString().slice(0, 5));
            currentTime.setMinutes(currentTime.getMinutes() + durationInMinutes);
        }

        setAvailableTimeSlots(slots);
    };

    const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        setStyleDuration(input);

        if (/^[1-9]:[0-5][0-9]$/.test(input)) {
            updateAvailableTimeSlots(input);
        }
    };


    const handleHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedHour(e.target.value);
    };

    const handleMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMinute(e.target.value);
    };

    const handleAddTime = () => {
        const selectedTime = `${selectedHour}:${selectedMinute}`;

        if (!availableTimeSlots.includes(selectedTime)) {
            toast.error('Please select a valid time slot based on the style duration.');
            return;
        }

        if (!selectedTimes.includes(selectedTime)) {
            setSelectedTimes([...selectedTimes, selectedTime]);
        } else {
            toast.error('This time slot has already been added.');
        }
    };

    const handleRemoveTime = (timeToRemove: string) => {
        setSelectedTimes(selectedTimes.filter((time) => time !== timeToRemove));
    };

    const renderHourOptions = () => {
        const hourOptions = [];

        for (let hour = 8; hour < 19; hour++) {
            const formattedHour = String(hour).padStart(2, "0");
            hourOptions.push(
                <option key={formattedHour} value={formattedHour}>
                    {formattedHour}
                </option>
            );
        }

        return hourOptions;
    };

    const renderMinuteOptions = () => {
        const minuteOptions = [];

        for (let minute = 0; minute < 60; minute++) {
            const formattedMinute = String(minute).padStart(2, "0");
            minuteOptions.push(
                <option key={formattedMinute} value={formattedMinute}>
                    {formattedMinute}
                </option>
            );
        }

        return minuteOptions;
    };


    useEffect(() => {
        if (style && services.length > 0) {
            setSelectedServiceName(style.serviceName || "");

            const foundService = services.find(
                (service) => service.serviceName === style.serviceName
            );
            if (foundService) {
                setSelectedService(foundService.serviceId);
            }



            setStyles(style.style || "");
            setDeposit(style.styleDeposit?.toString() || "");
            setServicePrice(style.stylePrice || "");
            setDiscount(style.discount?.toString() || "");
            setDiscountedPrice(style.value?.toString() || "");
            setShortDescription(style.styleShortDescription || "");
            setFullDetails(style.styleFullDetailsOfService || "");
            setExistingImageUrl(typeof style.styleImage === 'string' ? style.styleImage : "");
            // setStyleDuration(style.styleDuration || '')
            updateAvailableTimeSlots(style.styleDuration || '1:00');
            setSelectedDates(style.availableDays || []);
            setSelectedTimes(style.availableTimes || []);
        }
    }, [style, services]);

  
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        formData.append("serviceId", String(selectedService));
        formData.append("serviceName", selectedServiceName);
        formData.append("style", styles);
        formData.append("styleShortDescription", shortDescription);
        formData.append("styleFullDetailsOfService", fullDetails);
        formData.append("stylePrice", servicePrice);
        if (uploadedFile) {
            formData.append("styleImage", uploadedFile);
        } else if (existingImageUrl) {
            // Fetch the image file from the URL
            const response = await fetch(existingImageUrl);
            const blob = await response.blob();
            const fileName = existingImageUrl.split('/').pop() || 'image.jpg';
            const file = new File([blob], fileName, { type: blob.type });
            formData.append("styleImage", file);
        } else {
            throw new Error("Please upload an image for the style.");
        }
        formData.append('styleDuration', styleDuration);
        formData.append("styleDeposit", deposit);
        formData.append("discount", discount);
        formData.append("value", discountedPrice);

        const availableDates = selectedDates.map((date) => date.toString());
        const availableTimes = selectedTimes.map((time) => time.toString());

        formData.append("availableDays", availableDates.join(","));
        formData.append("availableTimes", availableTimes.join(","));

        try {
            let response;
            if (style) {
                // Update existing style
                response = await api.put(
                    `/api/styles/update/${style.styleId}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
            } else {
                // Create new style
                response = await api.post(
                    `/api/styles/add`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
            }

            if (response.status !== 200) {
                throw new Error(style ? "Failed to update style" : "Failed to add style");
            }

            toast.success(style ? "Style updated successfully!" : "Style added successfully!");
            onUpdate(style ? style.styleId : response.data.styleId, formData);
        } catch (err) {
            toast.error(style ? "Failed to update style. Please check all fields and try again." : "Failed to add style. Please check all fields and try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const calculateDiscountedPrice = () => {
        const price = parseFloat(servicePrice);
        const discountPercentage = parseFloat(discount);
        if (!isNaN(price) && !isNaN(discountPercentage)) {
            const discountAmount = (price * discountPercentage) / 100;
            const discountedPrice = price - discountAmount;
            setDiscountedPrice(discountedPrice.toString());
        }
    };

    return (
        <div className="w-full px-4 sm:px-6 lg:px-8 mx-auto border-none p-4">
            <h2 className="text-xl font-semibold text-brand-primary">
                {style ? "Update Style" : "Add Service"}
            </h2>
            <form
                onSubmit={handleSubmit}
                className="flex flex-col justify-between min-h-screen"
            >
                <div className="grid md:grid-cols-3 justify-center gap-12">
                    <div className="mt-6 grid gap-4 lg:gap-6">
                        <div>
                            <label className="block mb-2 text-sm text-brand-primary font-medium">
                                Service Name
                            </label>
                            <select
                                value={selectedService}
                                className="py-3 px-4 block w-full border-none text-sm text-gray-700 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                disabled
                            >
                                <option value={selectedService}>{selectedServiceName}</option>
                            </select>
                        </div>

                        <div>
                            <label className="block mb-2 text-sm text-brand-primary font-medium">
                                Styles
                            </label>
                            <input
                                type="text"
                                value={styles}
                                onChange={(e) => setStyles(e.target.value)}
                                className="py-3 px-4 block w-full border-none text-sm text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                placeholder="Medium"
                            />
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                            <div>
                                <label className="block mb-2 text-sm font-medium text-brand-primary">
                                    Deposit
                                </label>
                                <input
                                    type="text"
                                    value={deposit}
                                    onChange={(e) => setDeposit(e.target.value)}
                                    className="py-3 px-4 block w-full border-none text-sm text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                    placeholder="20"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 text-sm text-brand-primary font-medium">
                                    Service Price
                                </label>
                                <input
                                    type="text"
                                    name="service price"
                                    value={servicePrice}
                                    onChange={(e) => setServicePrice(e.target.value)}
                                    className="py-3 px-4 block w-full border-none text-sm text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                    placeholder="160"
                                />
                            </div>
                        </div>

                        <div className="grid gdaterid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                            <div>
                                <label className="block mb-2 text-sm text-brand-primary font-medium">
                                    Discount %
                                </label>
                                <input
                                    type="text"
                                    name="discount"
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                    onBlur={calculateDiscountedPrice}
                                    className="py-3 px-4 block w-full border-none text-sm text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                    placeholder="Nill"
                                />
                            </div>

                            <div>
                                <label className="block mb-2 text-sm text-brand-primary font-medium">
                                    Discounted Price
                                </label>
                                <input
                                    type="text"
                                    name="discountedPrice"
                                    value={discountedPrice}
                                    readOnly
                                    className="py-3 px-4 block w-full border-none text-sm text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                    placeholder="0"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex-col mt-6 grid gap-4 lg:gap-6">
                        <div>
                            <label className="block mb-2 text-sm text-brand-primary font-medium">
                                Short Description
                            </label>
                            <textarea
                                value={shortDescription}
                                onChange={(e) => setShortDescription(e.target.value)}
                                className="w-full p-3 text-sm text-gray-700 placeholder-gray-400 bg-gray-100 border border-brand-secondary rounded-lg focus:border-brand-primary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                placeholder="Message"
                                id="message"
                            ></textarea>
                        </div>

                        <div>
                            <label className="block mb-2 text-sm text-brand-primary font-medium">
                                Full details of Service
                            </label>
                            <textarea
                                value={fullDetails}
                                onChange={(e) => setFullDetails(e.target.value)}
                                className="w-full p-3 text-sm text-gray-700 placeholder-gray-400 bg-gray-100 border border-brand-secondary rounded-lg focus:border-brand-primary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                placeholder="Full details"
                                id="message"
                            ></textarea>
                        </div>

                        <div className="flex items-center">
                            <div>
                                <label className="block mb-2 text-sm text-brand-primary font-medium">
                                    Style Duration (hours:minutes)
                                </label>
                                <input
                                    type="text"
                                    value={styleDuration}
                                    onChange={handleDurationChange}
                                    placeholder="1:00"
                                    pattern="^[0-9]+:[0-5][0-9]$"
                                    title="Enter duration in format: hours:minutes (e.g., 1:30 for 1 hour 30 minutes)"
                                    className="appearance-none py-3 px-4 block w-full border-none text-sm text-gray-700 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex-col mt-6 grid gap-4 lg:gap-6">
                        <div className="flex items-center justify-center w-full">
                            <div
                                {...getRootProps()}
                                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                            >
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p className="text-gray-500">Drop the file here...</p>
                                ) : (
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            {uploadedFile ? (
                                                <img
                                                    src={URL.createObjectURL(uploadedFile)}
                                                    alt="Uploaded"
                                                    className="w-full h-auto mb-4 rounded-lg"
                                                />
                                            ) : existingImageUrl ? (
                                                <div className="relative w-full h-full">
                                                    <img
                                                        src={existingImageUrl}
                                                        alt="Existing"
                                                        className="w-full h-full object-cover rounded-lg"
                                                    />
                                                    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300">
                                                        <p className="text-white text-center">Click or drag to replace image</p>
                                                    </div>
                                                </div>
                                            ) : (
                                            <>
                                                <svg
                                                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 20 16"
                                                >
                                                    <path
                                                        stroke="currentColor"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="2"
                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                    />
                                                </svg>
                                                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                    <span className="font-semibold">Click to upload</span>{" "}
                                                    or drag and drop
                                                </p>
                                                <p className="text-xs text-gray-500 dark:text-gray-400">
                                                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                                                </p>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="text-xl font-bold mb-4 text-brand-primary">
                            Select Available Dates
                        </div>
                        <Calendar
                            onChange={handleDateChange}
                            selectRange={true}
                            selectedDates={selectedDates}
                            // minDate={new Date()}
                        />
                    </div>

                    <div className="w-full mt-8 md:mt-0 md:ml-8">
                        <div className="text-xl font-bold mb-4 text-brand-primary">
                            Select Available Times
                        </div>
                        <div className="flex flex-wrap items-center mb-4">
                            <select
                                value={`${selectedHour}:${selectedMinute}`}
                                onChange={(e) => {
                                    const [hour, minute] = e.target.value.split(':');
                                    setSelectedHour(hour);
                                    setSelectedMinute(minute);
                                }}
                                className="w-full p-2 border border-gray-300 rounded-md"
                            >
                                <option value="">Select a time</option>
                                {availableTimeSlots.map((slot) => (
                                    <option key={slot} value={slot}>
                                        {slot}
                                    </option>
                                ))}
                            </select>
                            <button
                                type="button"
                                onClick={handleAddTime}
                                className="w-full md:w-auto px-4 py-2 bg-brand-primary text-white rounded-md ml-2 mt-2 md:mt-0"
                            >
                                Add Time
                            </button>
                        </div>
                        <div className="mt-4">
                            <p>Selected Times:</p>
                            <ul>
                                {selectedTimes.map((time) => (
                                    <li key={time} className="flex items-center justify-between mb-2">
                                        {time}
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveTime(time)}
                                            className="ml-2 text-red-500"
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between sm:mt-6 mt-7">
                    <div className="flex justify-between sm:mt-6 mt-7">
                        <div className="flex justify-between w-full gap-4">
                            <button
                                type="button"
                                className="py-3 px-16 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-brand-primary hover:border-brand-primary hover:text-brand-primary disabled:opacity-50 disabled:pointer-events-none0"
                                onClick={() =>
                    // window.confirm('Are you sure you want to cancel?') &&
                                    navigate('/admin/services')
                                }
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-16 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-brand-primary rounded-lg hover:bg-brand-secondary focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <div className="flex items-center justify-center">
                                        <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                                        <span className="ml-2">Loading...</span>
                                    </div>
                                ) : (
                                    style ? "Update" : "Submit"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default UpdateServiceInfoMain;
