import { useNavigate } from "react-router-dom";
import bgImg from "../../../assets/svg/SectionTwoBG.svg";
import flowerGirl from "../../../assets/svg/flowerGirl.svg";

const HomeSectionTwo = () => {
  const navigate = useNavigate()

  const aboutUs = () => {
    navigate('/about')
  }

  return (
    <div
      className="flex flex-col justify-center items-center min-h-screen bg-cover bg-center py-16 sm:py-24 md:py-32"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center justify-between">
        <div className="text-center lg:text-left lg:w-1/2 mb-12 lg:mb-0 z-10">
          <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-semibold text-[#FF0066] mb-6">
            About us
          </h2>
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-[#FF0066] max-w-2xl mx-auto lg:mx-0 mb-8 leading-relaxed">
            Promoting hair health for children in Calgary by offering hair
            styling, treatments, and educating them about their hair from a
            young age.
          </p>
          <div className="flex justify-center lg:justify-start">
            <button
              className="bg-[#A020F0] text-white px-6 sm:px-8 md:px-10 py-3 sm:py-4 rounded-full uppercase text-sm sm:text-base md:text-lg font-medium hover:bg-[#8010D0] transition-colors duration-300"
              onClick={aboutUs}
            >
              Learn more about us
            </button>
          </div>
        </div>
        <div className="lg:w-1/2 mt-12 lg:mt-0 z-20">
          <img
            src={flowerGirl}
            alt="flower-girl"
            className="w-full max-w-md mx-auto lg:max-w-none lg:ml-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeSectionTwo;
