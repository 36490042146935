import React, { useState } from 'react';
import { api } from '../../api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../resuseables/Loading';

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.post(`/auth/forgot-password?email=${email}`,);
            // Show a success message or redirect to another page
            if (response.status === 200) {
                toast.success("Password reset link sent to your email!");
                setEmail('');
                navigate('/');
            }

        } catch (error) {
            // Show an error message
            console.error('Error:', error);
            toast.error("Error sending reset link.");
        }
    };

    return (
        <div className="min-h-[] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot Your Password?</h2>
                </div>

                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                    {loading ? (
                        <div className="flex justify-center items-center w-full">
                            <Loading />
                        </div>
                    ) : (
                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#A020F0] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                            >
                                Send Reset Link
                                </button>
                                {/* let users know to check their inbox or spam box */}
                                <p className="text-center text-gray-500 text-xs">
                                    Please check your inbox or spam box for the reset link.
                                </p>

                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;