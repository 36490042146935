import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store/store';
import { fetchServiceStyles } from '../redux/reducers/serviceTypeStylesSlice';
import ServiceCardItem from './ServiceCardItem';
import Loading from './resuseables/Loading';

interface ServiceCardListFilterProps {
  selectedServiceId: number;
}

const ServiceCardListFilter = ({ serviceStyle }: { serviceStyle: any[] }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 justify-center px-8 mb-8">
      {serviceStyle.map((style: any) => (
        <div key={style.styleId}>
          <ServiceCardItem service={style} />
        </div>
      ))}
    </div>
  );
};

export default ServiceCardListFilter;
