import React, { useEffect } from 'react'
import AboutSectionOne from './sections/about/AboutSectionOne'
import AboutSectionTwo from './sections/about/AboutSectionTwo'
import ContactUs from './ContactUs'
import Footer from '../components/Footer'

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=' xl:mt-20 sm:mt-2 '>
      <AboutSectionOne />
      <AboutSectionTwo />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default AboutUs