import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllBookedServices } from '../../api';
import { RootState } from '../store/store';

export const fetchAllBookedServices = createAsyncThunk(
  'bookedServices/fetchBookedServices',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const token = state.user.userData?.token;
    const bookedServices = await getAllBookedServices(token);
    return bookedServices;
  }
);

export interface BookedServicesState {
  bookedServices: any[];
  status: string;
  error: string | null;
}

const initialState: BookedServicesState = {
  bookedServices: [],
  status: 'idle',
  error: null,
};

const bookedServicesSlice = createSlice({
  name: 'bookedServices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBookedServices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllBookedServices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookedServices = action.payload;
      })
      .addCase(fetchAllBookedServices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message || null;
      });
  },
});

export default bookedServicesSlice.reducer;
