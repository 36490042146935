import React, { useEffect, useState } from "react";
import { register } from "../../api";
import { toast } from "react-toastify";
import Loading from "../resuseables/Loading";


const Register = ({ onCloseModal, onSwitchToLogin }: any) => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState<any>({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    validateForm();
  }, [userData]);

  const validateForm = () => {
    const { firstName, lastName, email, password } = userData;
    const errors: any = {};

    if (!firstName) {
      errors.firstName = "First name is required";
    }
    if (!lastName) {
      errors.lastName = "Last name is required";
    }
    if (!email) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Invalid email address";
    }
    if (!password) {
      errors.password = "Password is required";
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/.test(password)) {
      errors.password = "Password must contain at least 8 characters, including an uppercase letter, a lowercase letter, a number, and a special character";
    }

    setFormErrors(errors);
    setIsFormValid(Object.keys(errors).length === 0);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    register(userData)
      .then((res) => {
        console.log(res);
        setLoading(false);
        toast.success("Account created successfully");
        onCloseModal();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Failed to register. Please try again.");
      });
  };

  return (
    <div className="flex flex-col items-start justify-start w-full p-4 sm:p-6 md:p-8 lg:p-16 max-w-md mx-auto">
      <h3 className="text-2xl sm:text-3xl font-semibold text-center text-[#13113F] w-full">
        Create an Account
      </h3>
      <p className="text-xs sm:text-sm text-center text-[#13113F] mt-2 sm:mt-4 w-full">
        Already have an account?{" "}
        <span className="text-[#E20F7F] cursor-pointer" onClick={onSwitchToLogin}>
          Sign In
        </span>
      </p>
      <form
        className="flex flex-col items-center justify-center w-full mt-4 sm:mt-6"
        onSubmit={handleSubmit}
      >
        {["firstName", "lastName", "email", "password"].map((field) => (
          <div key={field} className="flex flex-col items-start justify-start w-full mb-3 sm:mb-4">
            <label
              htmlFor={field}
              className="text-xs sm:text-sm text-[#13113F] font-semibold"
            >
              {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
            </label>
            <input
              type={field === "password" ? "password" : "text"}
              id={field}
              name={field}
              value={userData[field as keyof typeof userData]}
              onChange={handleChange}
              placeholder={`Enter your ${field.replace(/([A-Z])/g, ' $1').toLowerCase().trim()}`}
              className="w-full h-8 sm:h-10 border border-[#E5E5E5] rounded-md px-3 sm:px-4 mt-1 sm:mt-2 bg-slate-100 text-xs sm:text-sm"
            />
            {formErrors[field] && (
              <p className="text-red-500 text-xs mt-1">{formErrors[field]}</p>
            )}
          </div>
        ))}
        <button
          type="submit"
          className="w-full h-8 sm:h-10 bg-[#E20F7F] rounded-md text-white font-semibold mt-4 sm:mt-6 text-xs sm:text-sm"
          disabled={!isFormValid || loading}
        >
          {loading ? "Please wait..." : "Sign Up"}
        </button>
      </form>
    </div>
  );
};

export default Register;


