import React from 'react';

interface ViewBookingDetailsProps {
    bookingDetails: {
        bookingId: number;
        bookingDate: string;
        bookingTime: string;
        serviceName: string;
        styleId: number;
        styleName: string;
        styleDuration: string;
        styleDeposit: number;
        discount: number;
        value: number;
        fullName: string;
        email: string;
        mobilePhone: string;
        childName: string;
        policyAgreement: boolean;
        cancellationPolicy: boolean;
        deposit: string;
        paymentStatus: string

    };
}

const ViewBookingDetails: React.FC<ViewBookingDetailsProps> = ({
    bookingDetails,
}) => {
    function formatTime(timeString) {
        // Check if the timeString is valid
        if (!timeString || typeof timeString !== 'string') {
            return 'Invalid Time';
        }

        // Split the time string into hours and minutes
        const [hours, minutes] = timeString.split(':');

        // Convert hours to number for comparison
        const hoursNum = parseInt(hours, 10);

        // Determine if it's AM or PM
        const period = hoursNum >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        const formattedHours = hoursNum % 12 || 12;

        // Return the formatted time string
        return `${formattedHours}:${minutes} ${period}`;
    }

    console.log("bookingDetails",bookingDetails);
    return (
        <div className="bg-[#FADADF] p-4 sm:p-6 rounded-lg shadow-md">
            <h3 className="text-xl sm:text-2xl font-bold text-[#A020F0] mb-4 sm:mb-6 text-center">Booking Summary</h3>
            <div className="max-w-xl mx-auto bg-white p-4 sm:p-6 rounded-lg shadow-md">
                <div className="space-y-4 sm:space-y-6">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center border-b pb-4">
                        <p className="text-xs sm:text-sm text-gray-600 font-semibold mb-1 sm:mb-0">Date of Appointment</p>
                        <p className="text-base sm:text-lg font-bold text-[#A020F0]">
                            {bookingDetails.bookingDate}
                        </p>
                    </div>

                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                        <p className="text-xs sm:text-sm text-gray-600 font-semibold mb-1 sm:mb-0">Time of Appointment</p>
                        <p className="text-base sm:text-lg font-bold text-[#A020F0]">
                            {formatTime(bookingDetails.bookingTime)}
                        </p>
                    </div>

                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                        <p className="text-xs sm:text-sm text-gray-600 font-semibold mb-1 sm:mb-0">Service:</p>
                        <p className="text-base sm:text-lg font-semibold text-[#A020F0]">{bookingDetails.serviceName}</p>
                    </div>

                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                        <p className="text-xs sm:text-sm text-gray-600 font-semibold mb-1 sm:mb-0">Price:</p>
                        <p className="text-base sm:text-lg font-bold text-[#A020F0]">
                            ${bookingDetails.value}
                        </p>
                    </div>

                    {bookingDetails.deposit === "NOT_PAID" && (
                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center bg-pink-100 p-3 rounded">
                            <p className="text-xs sm:text-sm font-semibold mb-1 sm:mb-0">Deposit Fee *</p>
                            <p className="text-base sm:text-lg font-bold text-[#A020F0]">
                                ${bookingDetails.styleDeposit}
                            </p>
                        </div>
                    )}
                    {/* If deposit fee has been paid that is bookingDetails.deposit  === "PAID", then show Balance, this balace will be bookingDetails.deposit - bookingDetails.styleDeposit */}

                    {bookingDetails.deposit === "PAID" &&
                    bookingDetails.paymentStatus !== "CONFIRMED"
                    && (
                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center bg-pink-100 p-3 rounded">
                            <p className="text-xs sm:text-sm font-semibold mb-1 sm:mb-0">Balance</p>
                            <p className="text-base sm:text-lg font-bold text-[#A020F0]">
                                ${bookingDetails.value - bookingDetails.styleDeposit}
                            </p>
                        </div>
                    )}

                    <div className="border-t pt-4 space-y-3">
                        {bookingDetails.deposit === "NOT_PAID" && (
                        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                            <p className="text-xs sm:text-sm font-semibold text-gray-600 mb-1 sm:mb-0">Deposit Status</p>
                            <p className="text-base sm:text-lg font-bold text-[#A020F0]">
                                {bookingDetails.deposit === "NOT_PAID" ? "NOT PAID" : bookingDetails.deposit}
                            </p>
                            </div>
                        )}
                        {bookingDetails.paymentStatus === "CONFIRMED" && (
                            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                                <p className="text-xs sm:text-sm font-semibold text-gray-600 mb-1 sm:mb-0">Payment Status</p>
                                <p className="text-base sm:text-lg font-bold text-[#A020F0]">
                                    {bookingDetails.paymentStatus === "CONFIRMED" ? "PAID" : bookingDetails.paymentStatus}
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                {bookingDetails.deposit === "NOT_PAID" && (
                    <div className="mt-4 sm:mt-6 p-3 sm:p-4 bg-yellow-50 rounded-lg border border-yellow-200">
                        <p className="text-xs sm:text-sm text-gray-700 leading-relaxed">
                            Kindly remit a <span className="font-bold text-[#A020F0]">${bookingDetails.styleDeposit}</span> deposit fee.
                            Please make payment to{" "}
                            <span className="font-bold">beenahairatrading@gmail.com</span>,
                            with your child's name and hair style as remark.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewBookingDetails;
