import React, { useState } from 'react';
import { login } from '../../api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Login = ({ onCloseModal, onLoginSuccess, onSwitchToRegister }: any) => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    login(userData)
      .then((res) => {
     
        setLoading(false);
        toast.success('Login successful');
        onLoginSuccess(res);
        onCloseModal();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError('Failed to login. Please try again.');
      });
  };

  const handleForgotPassword = () => {
    window.location.href = '/forgot-password';
    onCloseModal();
  }

  return (
    <div className="flex flex-col items-start justify-start w-full p-16  z-[5000]">
      <h3 className="text-3xl font-semibold text-center text-[#13113F]">
        Welcome Back
      </h3>
      <p className="text-sm text-center text-[#13113F] mt-4">
        Don't have an account?{' '}
        <span className="text-[#E20F7F] cursor-pointer" onClick={onSwitchToRegister}>Sign Up</span>
      </p>
      <form
        className="flex flex-col items-center justify-center w-full mt-6"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col items-start justify-start w-full">
          <label
            htmlFor="email"
            className="text-sm text-[#13113F] font-semibold"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            className="w-full h-10 border border-[#E5E5E5] rounded-md px-4 mt-2 bg-slate-100"
          />
        </div>
        <div className="flex flex-col items-start justify-start w-full mt-4">
          <label
            htmlFor="password"
            className="text-sm text-[#13113F] font-semibold"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={userData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            className="w-full h-10 border border-[#E5E5E5] rounded-md px-4 mt-2 bg-slate-100"
          />
        </div>
        {error && (
          <p className="text-sm text-red-500 mt-4 w-full text-center">{error}</p>
        )}


        {/* <p className="text-sm text-slate-400 mt-4">
          Password must contain a minimum of 8 characters, with an uppercase
          letter, a lowercase letter, a number and a special character.
        </p> */}
        <button
          type="submit"
          className="w-full h-10 bg-[#E20F7F] rounded-md text-white font-semibold mt-6"
        >
          {loading ? 'Please wait...' : 'Sign In'}
        </button>
      </form>
      <p className="text-sm text-[#13113F] mt-4 cursor-pointer text-left underline
       hover:text-[#E20F7F]
      "
        onClick={handleForgotPassword}
      >
        Forgot password?
      </p>
    </div>
  );
};

export default Login;

