import stylesReducer, { StylesState } from '../reducers/stylesSlice';
import serviceTypeReducer, {
  ServiceTypeState,
} from '../reducers/serviceTypeSlice';
import { UserState } from '../reducers/userSlice';
import { ServiceTypeStylesState } from '../reducers/serviceTypeStylesSlice';
import bookingsReducer, { BookingsState } from '../reducers/bookingSlice';
import bookedServicesReducer, {
  BookedServicesState,
} from '../reducers/bookedServicesSlice';
import userReducers, { UserServicesState } from '../reducers/userSlice';

import { configureStore } from '@reduxjs/toolkit';
import serviceTypeStylesReducer from '../reducers/serviceTypeStylesSlice';
import createBookingReducer, {
  BookingState,
} from '../reducers/createBookingSlice';
import updateBookingReducer, {
  UpdateBookingState,
} from '../reducers/updateBookingSlice';

export interface RootState {
  bookings: BookingsState;
  bookedServices: BookedServicesState;
  styles: StylesState;
  serviceTypes: ServiceTypeState;
  serviceTypeStyles: ServiceTypeStylesState;
  user: UserState;
  userServices: UserServicesState;
  createBooking: BookingState;
  updateBooking: UpdateBookingState;
}

const store = configureStore({
  reducer: {
    bookings: bookingsReducer,
    bookedServices: bookedServicesReducer,
    styles: stylesReducer,
    serviceTypes: serviceTypeReducer,
    serviceTypeStyles: serviceTypeStylesReducer,
    createBooking: createBookingReducer,
    updateBooking: updateBookingReducer,
    ...userReducers,
  },
});

export type AppDispatch = typeof store.dispatch;
export default store;
