import { ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { RootState } from '../redux/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStyles } from '../redux/reducers/stylesSlice';
import ReactPaginate from 'react-paginate';

import ServiceCardItem from './ServiceCardItem';

const ServiceCardList = ({ styles }: { styles: any[] }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const stylesPerPage = 8;

  const indexOfLastStyle = (pageNumber + 1) * stylesPerPage;
  const indexOfFirstStyle = indexOfLastStyle - stylesPerPage;
  const currentStyles = styles.slice(indexOfFirstStyle, indexOfLastStyle);

  const handlePageChange = ({ selected }: { selected: number }) => {
    setPageNumber(selected);
  };

  return (
    <div className="mx-auto px-2 sm:px-4 md:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center mb-8">
        {currentStyles.map((style: any) => (
          <div key={style.styleId} className="w-full">
            <ServiceCardItem service={style} />
          </div>
        ))}
      </div>
      <ReactPaginate
        previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={Math.ceil(styles.length / stylesPerPage)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={'flex flex-wrap justify-center items-center mt-8 gap-2'}
        activeClassName={'bg-[#EDBACF] text-[#A020F0] font-bold'}
        disabledClassName={'text-gray-400 cursor-not-allowed'}
        previousClassName={'bg-gray-200 px-3 py-2 rounded-md text-sm'}
        nextClassName={'bg-gray-200 px-3 py-2 rounded-md text-sm'}
        pageClassName={'bg-gray-200 px-3 py-2 rounded-md text-sm'}
        breakClassName={'px-3 py-2 text-sm'}
        pageLinkClassName={'w-full h-full flex items-center justify-center'}
        previousLinkClassName={'w-full h-full flex items-center justify-center'}
        nextLinkClassName={'w-full h-full flex items-center justify-center'}
        breakLinkClassName={'w-full h-full flex items-center justify-center'}
      />
    </div>
  );
};

export default ServiceCardList;
