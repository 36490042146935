import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getServiceTypeStyles } from "../../api";

export const fetchServiceStyles = createAsyncThunk(
  "servicestyles/fetchServiceTypeStyles",
  async (id: number) => {
    const serviceStyles = await getServiceTypeStyles(id);
    return serviceStyles;
  }
);

export interface ServiceTypeStylesState {
  serviceStyle: any[];
  status: string;
  error: string | null;
}

const initialState: ServiceTypeStylesState = {
  serviceStyle: [],
  status: "idle",
  error: null,
};

const serviceTypeStylesSlice = createSlice({
  name: "serviceStyle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceStyles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchServiceStyles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.serviceStyle = action.payload;
      })
      .addCase(fetchServiceStyles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || null;
      });
  },
});

export default serviceTypeStylesSlice.reducer;
