import Footer from "../components/Footer";
import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";

const ForgotPasswordPage = () => {
    return (
        <div>
            <ForgotPasswordForm />
            <Footer />
        </div>
    );
};

export default ForgotPasswordPage;