import React from "react";
import brush1 from "../../../assets/svg/brush1.svg";
import brush2 from "../../../assets/svg/brush2.svg";
import girl1 from "../../../assets/svg/girl1.svg";
import girl2 from "../../../assets/svg/girl2.svg";
import girl3 from "../../../assets/svg/girl3.svg";
import brush4 from "../../../assets/svg/brush4.svg";
import { useNavigate } from "react-router-dom";

const HomeSectionThree = () => {
  const navigate = useNavigate();


  const handleNavigate = () => {
    window.scrollTo(0, 0);
    navigate('/services');
  }
  return (
    <div className="flex flex-col items-center justify-center mt-8 sm:mt-12 md:mt-16 px-4 sm:px-6 md:px-8 lg:px-12 w-full h-auto max-w-7xl mx-auto">
      <div className="flex flex-wrap justify-between items-center w-full mb-6 sm:mb-8 md:mb-12">
        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-semibold text-[#FF0066] text-left mb-4 sm:mb-0">
          Services
        </h2>
        <div className="flex items-center space-x-4 sm:space-x-8">
          <img
            src={brush1}
            alt="Brush 1"
            className="object-contain w-12 sm:w-16 md:w-20 lg:w-24"
          />
          <img
            src={brush2}
            alt="Brush 2"
            className="object-cover w-12 sm:w-16 md:w-20 lg:w-24"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 sm:gap-12 md:gap-16 w-full">
        {[
          { img: girl1, text: "Cornrows" },
          { img: girl2, text: "Braids" },
          { img: girl3, text: "Hair Prep" },
        ].map((item, index) => (
          <div key={index} className="flex flex-col items-center cursor-pointer"
            onClick={handleNavigate}
          >
            <img src={item.img} alt={`Girl ${index + 1}`} className="object-cover w-32 sm:w-40 md:w-48 lg:w-56" />
            <p className="text-xl sm:text-2xl md:text-3xl text-center text-[#FF0066] mt-4 sm:mt-6">
              {item.text}
            </p>
          </div>
        ))}
      </div>

      <div className="flex justify-start items-start mt-8 sm:mt-12 md:mt-16 w-full">
        <img src={brush4} alt="Brush 4" className="object-cover w-24 sm:w-32 md:w-40 lg:w-48" />
      </div>
    </div>
  );
};

export default HomeSectionThree;
