import React, { useState, useEffect } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import Modal from '../../modal/Modal';
import { PropagateLoader } from 'react-spinners';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { api } from '../../../api';
import { RootState } from '../../../redux/store/store';
import { useSelector } from 'react-redux';

interface Review {
    reviewId: number;
    review: string;
    reviewBy: string;
    createdAt: string;
}

const ReviewsTable: React.FC = () => {
    const token = useSelector((state: RootState) => state.user.userData?.token);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newReview, setNewReview] = useState({ review: '', reviewBy: '' });
    const [editingReview, setEditingReview] = useState<Review | null>(null);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [hoveredElement, setHoveredElement] = useState<number | null>(null);

    const pageSize = 8;
    const totalPages = Math.ceil(reviews.length / pageSize);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = reviews.slice(startIndex, endIndex);

    const headers = ['SN', 'Review Message', 'Full Name', 'Actions'];

    useEffect(() => {
        fetchReviews();
    }, []);

    const fetchReviews = async () => {
        try {
            setTableLoading(true);
            const response = await api.get('/api/reviews/all');
            setReviews(response.data);
        } catch (error) {
            console.error('Error fetching reviews:', error);
            toast.error('Failed to fetch reviews');
        } finally {
            setTableLoading(false);
        }
    };

    const handleOpenUpdate = (review: Review) => {
        setEditingReview(review);
        setNewReview({ review: review.review, reviewBy: review.reviewBy });
        setIsModalOpen(true);
    };

    const handleDeleteReview = async (reviewId: number) => {
        try {
            setLoading(true);
            await api.delete(`/api/reviews/${reviewId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            toast.success('Review deleted successfully');
            fetchReviews();
        } catch (error) {
            console.error('Error deleting review:', error);
            toast.error('Failed to delete review');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setLoading(true);
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            if (editingReview) {
                await api.put(`/api/reviews/${editingReview.reviewId}`, newReview, { headers });
                toast.success('Review updated successfully');
            } else {
                await api.post('/api/reviews/add', newReview, { headers });
                toast.success('Review added successfully');
            }
            fetchReviews();
            setIsModalOpen(false);
            setNewReview({ review: '', reviewBy: '' });
            setEditingReview(null);
        } catch (error) {
            console.error('Error submitting review:', error);
            toast.error(editingReview ? 'Failed to update review' : 'Failed to add review');
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <section className="px-4 mx-auto w-full">
            <div className="mt-6 md:flex md:items-center sm:justify-between">
                <p className="bg-white text-2xl">List of Reviews</p>

                <div className="flex justify-center items-center gap-3">
                    <div className="flex gap-2">
                        <button
                            type="button"
                            onClick={() => {
                                setEditingReview(null);
                                setNewReview({ review: '', reviewBy: '' });
                                setIsModalOpen(true);
                            }}
                            className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white disabled:opacity-50 disabled:pointer-events-none"
                        >
                            Add Review
                        </button>
                        <div className="relative flex items-center mt-4 md:mt-0">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 text-gray-400"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                    />
                                </svg>
                            </span>
                            <input
                                type="text"
                                placeholder="Search"
                                className="block w-full py-1.5 pl-10 pr-3 text-gray-700 bg-white border border-brand-secondary rounded-lg md:w-80 placeholder-gray-400 focus:border-brand-secondary focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden border md:rounded-lg">
                            {tableLoading ? (
                                <div className="flex justify-center items-center h-64">
                                    <PropagateLoader color="#FF0000" loading={tableLoading} size={15} />
                                </div>
                            ) : (
                                <>
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                {headers.map((header, index) => (
                                                    <th key={index} className="px-4 py-3.5 text-left text-sm font-semibold text-slate-950">
                                                        {header}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-brand-primary divide-y divide-gray-200">
                                            {currentData.map((review, index) => (
                                                <tr key={review.reviewId}>
                                                    <td className="px-4 py-4 text-sm text-white">{startIndex + index + 1}</td>
                                                    <td className="px-4 py-4 text-sm text-white break-words whitespace-normal max-w-xs">
                                                        {review.review}
                                                    </td>
                                                    <td className="px-4 py-4 text-sm text-white">{review.reviewBy}</td>
                                                    <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                                        <div
                                                            className="relative inline-block"
                                                            onMouseEnter={() => setHoveredElement(review.reviewId)}
                                                            onMouseLeave={() => setHoveredElement(null)}
                                                        >
                                                            <span className="cursor-pointer text-white">...</span>
                                                            {hoveredElement === review.reviewId && (
                                                                <div className="absolute top-full right-0 z-10 flex flex-col border w-24 bg-white">
                                                                    <button
                                                                        type="button"
                                                                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-teal-500 hover:bg-teal-100 hover:text-teal-800 disabled:opacity-50 disabled:pointer-events-none"
                                                                        onClick={() => handleOpenUpdate(review)}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-500 hover:bg-red-100 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-red-800/30 dark:hover:text-red-400 dark:focus:outline-none"
                                                                        onClick={() => handleDeleteReview(review.reviewId)}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className="flex items-center justify-end mt-6 gap-2">
                                        <button
                                            onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                                            disabled={currentPage === 1}
                                            className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Prev
                                        </button>

                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handlePageChange(index + 1)}
                                                className={`px-4 py-2 text-sm font-medium ${currentPage === index + 1
                                                    ? 'text-blue-600 bg-blue-100'
                                                    : 'text-gray-700 bg-white'
                                                    } border border-gray-300 rounded-md hover:bg-gray-50`}
                                                disabled={currentPage === index + 1}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}

                                        <button
                                            onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
                                            disabled={currentPage >= totalPages}
                                            className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <form onSubmit={handleSubmit}
                    className=''
                >
                    <div className="mb-4">
                        <label htmlFor="review" className="block text-gray-700 text-sm font-bold mb-2">
                            Review Message
                        </label>
                        <textarea
                            id="review"
                            value={newReview.review}
                            onChange={(e) => setNewReview({ ...newReview, review: e.target.value })}
                            maxLength={255}
                            className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-blue-300 transition duration-150 ease-in-out"
                            required
                            rows={6}
                            style={{ minHeight: '150px', resize: 'vertical' }}
                            placeholder="Enter your review here (max 255 characters)"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="reviewBy" className="block text-gray-700 text-sm font-bold mb-2">
                            Full Name
                        </label>
                        <input
                            type="text"
                            id="reviewBy"
                            value={newReview.reviewBy}
                            onChange={(e) => setNewReview({ ...newReview, reviewBy: e.target.value })}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-brand-primary hover:bg-[#c20c69] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            disabled={loading}
                        >
                            {loading ? 'Please wait...' : (editingReview ? 'Update' : 'Submit')}
                        </button>
                    </div>
                </form>
            </Modal>
        </section>
    );
};

export default ReviewsTable;