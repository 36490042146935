import React, { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
    isAllowed: boolean;
    redirectPath?: string;
    children?: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAllowed, redirectPath = '/', children }) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children || <Outlet />}</>;
};

export default ProtectedRoute;
