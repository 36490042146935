import React from 'react'
import bgImg from '../../../assets/svg/rabout2.svg'
import tosin from '../../../assets/svg/tosin.svg'
import spray from '../../../assets/svg/spray.svg'

const AboutSectionOne = () => {
    return (
        <div
            className="flex flex-col justify-center items-center sm:h-screen h-[40%]  bg-cover bg-center   xl:mt-0 "
            style={{ backgroundImage: `url(${bgImg})` }}
        >

            <div className='flex flex-col justify-between items-center px-20 w-full md:flex-row'>
                <div className="flex flex-col justify-center ">
                    {/* Title */}
                    <p className="text-xl md:text-2xl  xl:text-5xl 2xl:text-9xl font-semibold text-[#FF0066] text-center xl:text-left mb-3">
                        About Us
                    </p>
                    <p className="text-lg 2xl:text-3xl  text-center xl:text-justify text-[#555555] md:w-full xl:max-w-[520px]  2xl:max-w-[1200px] text-wrap leading-10 md:leading-7 mb-8 md:mb-0">
                        Promoting hair health for children in Calgary by offering hair styling, treatments, and educating them about their hair from a young age. Promoting hair health for children in Calgary by offering hair styling, treatments, and educating them about their hair from a young age
                    </p>
                    <p className="text-xl md:text-2xl  xl:text-5xl  font-semibold text-[#FF0066] text-center xl:text-left mb-4 mt-8">
                        Vision Statement
                    </p>
                    <p className="text-lg 2xl:text-3xl  text-center xl:text-justify text-[#555555] md:w-full xl:max-w-[520px]  2xl:max-w-[1200px] text-wrap leading-10 md:leading-7 mb-8 md:mb-0">
                        Promoting hair health for children in Calgary by offering hair styling, treatments, and educating them about their hair from a young age
                    </p>
                    {/* <div className="flex  justify-center xl:justify-start">
                        <button className="bg-[#A020F0] text-white px-8 md:px-12 py-3 md:py-5 rounded-full uppercase mt-8 md:mt-5">
                            Contact Us
                        </button>
                      

                    </div> */}
                    {/* <img src={spray
                    } alt="spray" className="object-contain w-[150px] h-[150px] mt-10" /> */}
                </div>

                <div className="flex justify-center items-center ">
                    <img
                        src={tosin}
                        alt="tosin"
                        className="object-contain w-[350px] 2xl:w-[510px] "
                    />
                </div>
            </div>

        </div>
    )
}

export default AboutSectionOne