import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getStyles } from '../../api';

export const fetchStyles = createAsyncThunk('styles/fetchStyles', async () => {
  const styles = await getStyles();
  return styles;
});

export interface StylesState {
  styles: any[];
  status: string;
  error: string | null;
}

const initialState: StylesState = {
  styles: [],
  status: 'idle',
  error: null,
};

const stylesSlice = createSlice({
  name: 'styles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStyles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStyles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.styles = action.payload;
      })
      .addCase(fetchStyles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message || null;
      });
  },
});

export default stylesSlice.reducer;
