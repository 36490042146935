import React from 'react'
import ContactForm from './sections/contact/ContactForm'
import OpeningHours from './sections/contact/OpeningHours'


const ContactUs = () => {
    return (
        <div className='px-4 md:px-20 w-full'>
            <div className='flex flex-col md:flex-row justify-between items-center md:space-x-32'>
                <ContactForm />
                <OpeningHours />
            </div>
        </div>
    )
}

export default ContactUs;
