import React, { useEffect, useState } from "react";
import bgImg from "../../../assets/svg/sectionFourBg.svg";
import scissors from "../../../assets/svg/scissors.svg";
import Card from "../../../components/Card";
import { toast } from "react-toastify";
import { api } from "../../../api";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Review {
  reviewId: number;
  review: string;
  reviewBy: string;
  createdAt: string;
}

const HomeSectionFour = () => {
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState<Review[]>([]);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/reviews/all');
      setReviews(response.data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
      toast.error('Failed to fetch reviews');
    } finally {
      setLoading(false);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ],
    adaptiveHeight: true,
    cssEase: 'linear',
    variableWidth: false,
    dotsClass: "slick-dots custom-slick-dots",
  };

  return (
    <div
      className="flex flex-col justify-center items-center min-h-screen bg-cover bg-center py-8 sm:py-12 md:py-16 lg:py-24"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="w-full px-4 sm:px-6 md:px-8 lg:px-12 max-w-7xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4 sm:gap-5 mb-8 sm:mb-12">
          <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-semibold text-[#A020F0] text-center sm:text-left mb-4 sm:mb-0">
            Reviews
          </h2>
          <div className="w-24 sm:w-28 md:w-32 lg:w-40">
            <img
              src={scissors}
              alt="Scissors"
              className="object-contain w-full"
            />
          </div>
        </div>
        {loading ? (
          <div className="text-center text-lg">Loading...</div>
        ) : (
          <div className="mx-auto max-w-full slider-container">
            <Slider {...settings}>
              {reviews.map((review) => (
                <div key={review.reviewId} className="px-2 py-4">
                  <Card
                    name={review.reviewBy}
                    description={review.review}
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeSectionFour;
