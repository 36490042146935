import { Outlet } from "react-router-dom";
import AdminSideBar from "./AdminSideBar";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
interface AdminLayoutProps {}
const AdminLayout = (props: AdminLayoutProps) => {
  return (
    <div className="">
      <Navbar />
      <div className="flex h-3/5 space-x-4 p-9">
        <AdminSideBar />
        <div className="flex flex-col flex-1 bg-white min-h-screen ">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AdminLayout;
