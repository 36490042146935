import React from "react";
import logo from "../assets/svg/logo.svg";
import {
  FaFacebook,
  FaInstagram,

  FaTwitter,
} from "react-icons/fa";
import {  useNavigate } from "react-router-dom";
import { ScrollToTopLink } from "./ScrollTopLink";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";

const Footer = () => {
  const navigate = useNavigate();
  const getrole = useSelector((state: RootState) => state.user.userData?.roles);
  const isAuthenticated = () => {
    return getrole && getrole.length > 0;
  };

  const userIsAuthenticated = isAuthenticated();

  const handleNavigate = () => {
    window.scrollTo(0, 0);
    navigate('/');
  }
  return (
    <>
      <div className="flex flex-col justify-center items-center bg-[#A020F0] text-white px-12 h-[100%]">
        <div className="flex flex-col md:flex-row justify-between items-center bg-gradient-to-r from-[#FF6699] to-[#A020F0] my-16 w-full p-4 md:p-0">
          <div className="flex items-center justify-center md:justify-start w-full md:w-auto">
            <img
              src={logo}
              alt="Logo"
              className="object-contain h-10 md:h-20 cursor-pointer"
              onClick={handleNavigate} 
            />
          </div>

          <div className="flex items-center justify-center md:justify-end w-full md:w-auto mt-4 md:mt-0">
            <div className="flex items-center space-x-4">
              <FaFacebook className="text-white cursor-pointer" size={20} />
              <a href='https://www.instagram.com/blhrt24?igsh=dTlvamQ4Y2NtZHd6&utm_source=qr'> <FaInstagram className="text-white cursor-pointer" size={20} />
              </a>
              <FaTwitter className="text-white cursor-pointer" size={20} />
              {/* <FaLinkedinIn className="text-white cursor-pointer" size={20} /> */}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full mb-5">
          <hr className=" border-[3px] border-white " />
        </div>

        <div className="flex flex-wrap justify-between items-center md:items-start w-full gap-4 mb-6 mt-10">
          <div className="flex flex-col w-full md:w-auto">
            <h2 className="text-xl font-semibold px-3 py-5">Explore</h2>
            <ScrollToTopLink to="/" className="text-base cursor-pointer p-3">Home</ScrollToTopLink>
            <ScrollToTopLink to="/about" className="text-base cursor-pointer p-3">About Us</ScrollToTopLink>
            <ScrollToTopLink to="/services" className="text-base cursor-pointer p-3">Services</ScrollToTopLink>
            {userIsAuthenticated ? (
              <ScrollToTopLink
                to="/dashboard/bookings"
                className="text-base cursor-pointer p-3 hidden md:block"
              >
                Appointments
              </ScrollToTopLink>
            ) : (
              ""
            )}
            {/* //Go to Users dashboard if logged in */}
          </div>

          {/* <div className="flex flex-col w-full md:w-auto ">
            <h2 className="text-xl font-semibold px-3 py-5">Utility Pages</h2>
            <p className="text-base cursor-pointer p-3">Style guide</p>
          </div> */}

          <div className="flex flex-col w-full md:w-auto mb-6">
            <h2 className="text-xl font-semibold px-3 py-5">Keep in Touch</h2>
            <p className="text-base cursor-pointer p-3">
              Address : 210 Cougarstone Cir SW Calgary Alberta
              T3H 4W5
            </p>
            <p className="text-base cursor-pointer p-3">Mail: beerlahairoitrading@gmail.com</p>
            <p className="text-base cursor-pointer p-3">Phone: +14036084846</p>
          </div>

          <div className="flex flex-col w-full md:w-auto">
            <h2 className="text-xl font-semibold px-3 py-5">Working Hours</h2>
            <p className="text-base cursor-pointer p-3">
              Mon to Fri : 7am - 6pm
            </p>
            <p className="text-base cursor-pointer p-3">Sat : 11am - 7pm</p>
            <p className="text-base cursor-pointer p-3">Sun: 11am - 4pm</p>
          </div>
        </div>

        <div className="flex justify-center items-center w-full mb-10">
          <p className="text-base">
            © {new Date().getFullYear()} BLHR. All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
