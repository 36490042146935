/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

interface VideoUploadsProps {}

const VideoUploads: React.FC<VideoUploadsProps> = () => {
  return (
    <div className="w-full px-4 sm:px-6 lg:px-8  mx-auto border-none p-4 ">
      <h2 className="text-xl font-semibold text-brand-primary">Video Upload</h2>
      <form className="flex flex-col justify-between min-h-screen ">
        <div className="grid md:grid-cols-2 justify-center gap-12">
          <div className="mt-6 grid gap-4 lg:gap-6">
            <div>
              <label className="block mb-2 text-sm text-brand-primary font-medium">
                Video Name
              </label>

              <input
                type="text"
                className="py-3 px-4 block w-full border-none text-sm  text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary  focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40 "
                placeholder="Hair Knowledge Tips"
              />
            </div>

            <div>
              <label className="block mb-2 text-sm text-brand-primary font-medium">
                Video Url
              </label>
              <input
                type="text"
                className="py-3 px-4 block w-full border-none text-sm  text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary  focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="www.video.com"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-brand-primary">
                Headliner
              </label>

              <select
                name="HeadlineAct"
                className="mt-1.5 w-full rounded-lg border-gray-300 text-gray-700 sm:text-sm py-3 px-4"
              >
                <option value="">All about hair</option>
                <option value="JM">Dummy data</option>
                <option value="JM">Dummy data</option>
                <option value="JM">Dummy data</option>
              </select>
            </div>

            <div>
              <label className="block mb-2 text-sm text-brand-primary font-medium">
                Date Added
              </label>
              <input
                type="text"
                className="py-3 px-4 block w-full border-none text-sm  text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary  focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40 "
                placeholder="11/12/2024"
              />
            </div>

            <div>
              <label className="block mb-2 text-sm text-brand-primary font-medium">
                Short Description
              </label>

              <textarea
                className="w-full  p-3 text-sm  text-gray-700 placeholder-gray-400 bg-gray-100 border border-brand-secondary rounded-lg focus:border-brand-primary  focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
                placeholder="Short Description"
              ></textarea>
            </div>
          </div>

          <div className=" flex-col mt-6 grid gap-4 lg:gap-6">
            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100  ">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
          </div>
        </div>

        <div className="flex justify-between sm:mt-6 mt-7">
          <div>
            <button
              type="button"
              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-brand-primary hover:border-brand-primary hover:text-brand-primary disabled:opacity-50 disabled:pointer-events-none0"
            >
              Delete Service
            </button>
          </div>

          <div className="inline-flex gap-4">
            <button
              type="button"
              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-brand-primary hover:border-brand-primary hover:text-brand-primary disabled:opacity-50 disabled:pointer-events-none0"
            >
              Cancel
            </button>
            <button className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-brand-primary rounded-lg hover:bg-brand-secondary focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default VideoUploads;
