import { useSelector } from 'react-redux';
import { RootState } from '../redux/store/store';
import { useNavigate } from 'react-router-dom';

const ProfileMenu = ({ onLogout, userData }: any) => {
  const navigate = useNavigate();
  const getrole = useSelector((state: RootState) => state.user.userData?.roles);

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  const handleAdmin = () => {
    navigate('/admin');
  };

  const handleDashboard = () => {
    navigate('/dashboard');
  };
  return (
    <div className="bg-white border rounded shadow-md absolute right-0 mt-2 w-48 z-10">
      <div className="py-2">
        {/* Avatar */}
        <div className="flex items-center px-4 py-2">
          {/* <FaUserCircle className="text-gray-600 mr-2" size={20} /> */}
          <span className="text-sm text-gray-800">
            {userData ? userData.email : 'Guest'}
          </span>
        </div>
        {/* Profile menu options */}
        <button
          className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 focus:outline-none"
          onClick={handleDashboard}
        >
          Dashboard
        </button>

        {getrole && getrole.includes('ROLE_ADMIN' as never) && (
          <button
            className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 focus:outline-none"
            onClick={handleAdmin}
          >
            Admin Management
          </button>
        )}

        <button
          onClick={handleLogout}
          className="block w-full text-left px-4 py-2 text-sm text-gray-800 hover:bg-gray-200 focus:outline-none"
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default ProfileMenu;
