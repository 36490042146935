import React from 'react'
import Navbar from '../../components/Navbar'
import DashboardSideBar from './DashboardSideBar'
import { Outlet } from 'react-router-dom'
import Footer from '../../components/Footer'

const DashboardLayout = () => {
  return (
    <div className="">
      <Navbar />
      <div className="flex h-3/5 space-x-4 p-9">
        <DashboardSideBar />
        <div className="flex flex-col flex-1 bg-white min-h-screen ">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default DashboardLayout