import React, { useState } from "react";
import { NavLink } from "react-router-dom";

interface VideoEntry {
  dateadded: string;
  url: string;
  videoname: string;
  videolocation: string;
  status: string;
}

interface TableProps {
  data: VideoEntry[];
  pageSize?: number;
  headers: string[];
}

const UserTable: React.FC<TableProps> = ({ data, pageSize = 10, headers }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / pageSize);
  const [hoveredElement, setHoveredElement] = useState<string | null>(null);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = data.slice(startIndex, endIndex);

  return (
    <section className="px-4 mx-auto">
      <div className="mt-6 md:flex md:items-center sm:justify-between">
        <div className="flex justify-center items-center gap-5">
          <p className="bg-white text-2xl">Uploads</p>

          <div className="flex gap-x-6">
            <NavLink to="/admin/video">
              <div className="flex gap-1">
                <input
                  type="radio"
                  name="form"
                  className="shrink-0 mt-0.5 border-gray-200 rounded-full text-red-500 focus:ring-brand-secondary"
                />
                <label className="text-lg">Video</label>
              </div>
            </NavLink>

            <NavLink to="/admin/quizes">
              <div className="flex gap-1">
                <input
                  type="radio"
                  name="form"
                  className="shrink-0 mt-0.5 border-gray-200 rounded-full  text-red-500 focus:ring-brand-secondary "
                />
                <label className="text-lg">Quizes</label>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="flex justify-center items-center gap-3 ">
          <div className="flex gap-2 ">
            <NavLink to="/admin/video">
              <button
                type="button"
                className="mt-4 sm:mt-0 py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white disabled:opacity-50 disabled:pointer-events-none"
              >
                Add New Video
              </button>
            </NavLink>
            <div className="relative flex items-center mt-4 md:mt-0">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </span>
              <input
                type="text"
                placeholder="Search"
                className="block w-full py-1.5 pl-10 pr-3 text-gray-700 bg-white border border-brand-secondary rounded-lg md:w-80 placeholder-gray-400  focus:border-brand-secondary  focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-6">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden border md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {headers.map((header, index) => (
                      <th
                        key={index}
                        className="px-4 py-3.5 text-left text-sm font-semibold text-slate-950"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-brand-primary divide-y divide-gray-200">
                  {currentData.map((user) => (
                    <tr key={user.dateadded}>
                      <td className="px-4 py-4 text-sm text-white">
                        {user.dateadded}
                      </td>
                      <td className="px-4 py-4 text-sm text-white">
                        {user.url}
                      </td>
                      <td className="px-4 py-4 text-sm text-white">
                        {user.videoname}
                      </td>
                      <td className="px-4 py-4 text-sm text-white">
                        {user.videolocation}
                      </td>
                      <td className="px-4 py-4 text-sm text-white">
                        {user.status}
                      </td>
                      <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                        <div
                          className="relative inline-block"
                          onMouseEnter={() => setHoveredElement(user.dateadded)}
                          onMouseLeave={() => setHoveredElement(null)}
                        >
                          <span className="cursor-pointer text-white">...</span>
                          {hoveredElement === user.dateadded && (
                            <div className="absolute top-full right-0 z-10 flex flex-col border w-24 bg-white">
                              <button
                                type="button"
                                className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-teal-500 hover:bg-teal-100 hover:text-teal-800 disabled:opacity-50 disabled:pointer-events-none"
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-500 hover:bg-red-100 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none dark:hover:bg-red-800/30 dark:hover:text-red-400 dark:focus:outline-none"
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex items-center justify-end mt-6 gap-2">
                <button
                  onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Prev
                </button>

                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`px-4 py-2 text-sm font-medium ${
                      currentPage === index + 1
                        ? "text-blue-600 bg-blue-100"
                        : "text-gray-700 bg-white"
                    } border border-gray-300 rounded-md hover:bg-gray-50`}
                    disabled={currentPage === index + 1}
                  >
                    {index + 1}
                  </button>
                ))}

                <button
                  onClick={() =>
                    handlePageChange(Math.min(totalPages, currentPage + 1))
                  }
                  disabled={currentPage >= totalPages}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserTable;
