import React from 'react';

const ComingSoon = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-[#FF6699] to-[#A020F0] ">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-white mb-4 animate-pulse">
                    Coming Soon!
                </h1>
                <p className="text-xl text-white opacity-75">
                    We're working on something exciting. Stay tuned!
                </p>
            </div>
        </div>
    );
};

export default ComingSoon;