import { useState } from "react";
import Login from "./Login";
import Modal from "./Modal";
import Register from "./Register";

interface MembersOnlyProps {
    onClose: () => void;
    onRegister: () => void;
    onLogin: () => void;
}

const MembersOnly: React.FC<MembersOnlyProps> = ({ onClose, onRegister, onLogin }) => {
    return (
        <div className="text-center p-6 bg-white rounded-lg shadow-xl">
            <h2 className="text-2xl font-bold mb-4 text-[#A020F0]">Members Only</h2>
            <p className="mb-6 text-gray-600">You need to be registered and logged in to book an appointment.</p>
            {/* <div className="space-x-4">
                <button
                    onClick={onRegister}
                    className="bg-[#FF6699] hover:bg-[#FF6699] text-white font-bold py-2 px-4 rounded"
                >
                    Register
                </button>
                <button
                    onClick={onLogin}
                    className="bg-[#A020F0] hover:bg-[#A020F0] text-white font-bold py-2 px-4 rounded"
                >
                    Login
                </button>
            </div> */}
        </div>
    );
};

export default MembersOnly;