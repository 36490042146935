import React, { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";

import Loading from '../resuseables/Loading';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';

const ResetPasswordForm = ({ token }) => {
    const [newPassword, setNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    console.log("token", token);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.post(`/auth/reset-password?token=${token}`, { newPassword });

            if (response.status === 200) {
                toast.success("Password reset successful!");
                setNewPassword('');
                navigate('/');
            }

        } catch (error) {

            console.error('Error:', error);
            toast.error("Error sending email.");
        }
    };

    return (
        <>
            <div className="min-h-[] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset Your Password</h2>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                        <input
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        />
                        {loading ? (
                            <div className="flex justify-center items-center w-full">
                                <Loading />
                            </div>
                        ) : (
                            <div>
                                <button
                                    type="submit"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#A020F0] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer"
                                >
                                    Reset Password
                                </button>
                            </div>
                        )}
                    </form>
                </div>

            </div>
        </>
    );
};

export default ResetPasswordForm;