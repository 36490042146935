import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useModal } from './ModalProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store/store';
import MembersOnly from './modal/MembersOnly';
import Modal from './modal/Modal';
import Login from './modal/Login';
import Register from './modal/Register';
import { useImageSize } from 'react-image-size';

interface Service {
  serviceName: string;
  style: string;
  styleShortDescription: string;
  stylePrice: string;
  styleImage: string;
  styleDuration: string;
  styleDeposit: string;
}

interface ServiceCardProps {
  service: Service;
}

const ServiceCardItem: React.FC<ServiceCardProps> = ({ service }) => {
  const [dimensions, { loading, error }] = useImageSize(service.styleImage);
  const [aspectRatio, setAspectRatio] = useState(1);
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const getrole = useSelector((state: RootState) => state.user.userData?.roles);

  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const isAuthenticated = () => {
    return getrole && getrole.length > 0;
  };

  const userIsAuthenticated = isAuthenticated();

  const handleBookNowClick = () => {
    if (userIsAuthenticated) {
      navigate('/bookingdetails', { state: { service } });
    } else {
      openModal(
        <MembersOnly
          onClose={closeModal}
          onRegister={() => {
            closeModal();
            setShowRegisterModal(true);
          }}
          onLogin={() => {
            closeModal();
            setShowLoginModal(true);
          }}
        />
      );
    }
  }

  useEffect(() => {
    if (dimensions) {
      setAspectRatio(dimensions.width / dimensions.height);
    }
  }, [dimensions]);

  return (
    <>
      <div className="max-w-sm rounded overflow-hidden ring-4 ring-white bg-[#A020F0] text-white shadow-2xl transform transition duration-500 hover:scale-105 ease-in-out md:max-w-md lg:max-w-xl">
        <div className="w-full h-0 pb-[100%] relative bg-[#A020F0]">
          {!loading && !error && (
            <img
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={service.styleImage}
              alt={service.serviceName}
              style={{ aspectRatio: aspectRatio }}
            />
          )}
        </div>
        <div className="px-4 py-2 flex flex-col md:flex-row justify-between items-center">
          <div className="font-bold text-lg md:w-52">{service.style}</div>
          <div className="mt-2 md:mt-0 rounded-full bg-[#FF6699] w-12 h-12 flex items-center justify-center text-white font-bold text-xl ring-4 ring-white">
            <p className="text-white text-base">${service.stylePrice}</p>
          </div>
        </div>
        <div className="px-4 py-3 text-white text-sm md:text-base">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p>Duration: {service.styleDuration}</p>
            {/* Separator for larger screens */}
            <div className="hidden md:block w-0.5 h-6 bg-white"></div>
            <p>Deposit: {service.styleDeposit}</p>
          </div>
        </div>
        <div className="px-4 py-3">
          <button
            className="bg-[#FF6699] hover:bg-[#FF6699] text-white font-bold py-2 px-4 rounded w-full cursor-pointer"
            onClick={handleBookNowClick}
          >
            Book Now
          </button>
        </div>
      </div>

      {showRegisterModal && (
        <Modal isOpen={true} onClose={() => setShowRegisterModal(false)}>
          <Register
            onCloseModal={() => setShowRegisterModal(false)}
            onSwitchToLogin={() => {
              setShowRegisterModal(false);
              setShowLoginModal(true);
            }}
          />
        </Modal>
      )}

      {showLoginModal && (
        <Modal isOpen={true} onClose={() => setShowLoginModal(false)}>
          <Login
            onCloseModal={() => setShowLoginModal(false)}
            onLoginSuccess={() => {
              setShowLoginModal(false);
              // Handle successful login (e.g., refresh authentication state)
            }}
            onSwitchToRegister={() => {
              setShowLoginModal(false);
              setShowRegisterModal(true);
            }}
          />
        </Modal>
      )}
    </>

  );
};

export default ServiceCardItem;
