import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllServiceTypes } from '../../api';

export const fetchServiceTypes = createAsyncThunk(
  'servicetypes/fetchServiceTypes',
  async () => {
    const serviceType = await getAllServiceTypes();
    return serviceType;
  }
);
export interface ServiceTypeState {
  services: any[];
  status: string;
  error: string | null;
}

const initialState: ServiceTypeState = {
  services: [],
  status: 'idle',
  error: null,
};

const serviceTypeSlice = createSlice({
  name: 'serviceType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceTypes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchServiceTypes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.services = action.payload;
      })
      .addCase(fetchServiceTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message || null;
      });
  },
});

export default serviceTypeSlice.reducer;
