import React from "react";
import hero1 from "../../../assets/svg/Hero1.svg";
import flower from "../../../assets/svg/flower.svg";
import { useNavigate } from "react-router-dom";

const HomeSectionOne = () => {
  const navigate = useNavigate();

  const handleReservation = () => {
    navigate("/services");
  }

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between px-4 sm:px-6 md:px-8 lg:px-12 max-w-7xl mx-auto py-8 lg:py-16">
      {/* Left Section */}
      <div className="flex flex-col justify-center w-full lg:w-1/2 mb-8 lg:mb-0">
        {/* Title */}
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-semibold text-[#FF0066] text-center lg:text-left mb-4 sm:mb-6 lg:mb-8">
          Beautifying the <br className="hidden sm:inline" /> girl child...
        </h1>

        {/* Description */}
        <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-center lg:text-left text-[#555555] max-w-2xl lg:max-w-none mx-auto lg:mx-0 mb-6 sm:mb-8 lg:mb-10">
          Promoting hair health for children in Calgary by offering hair
          styling, treatments, and educating them about their hair from a young
          age.
        </p>

        {/* Button */}
        <div className="flex justify-center lg:justify-start">
          <button
            className="bg-[#A020F0] text-white px-6 sm:px-8 md:px-10 py-3 sm:py-4 rounded-full uppercase text-sm sm:text-base md:text-lg font-medium hover:bg-[#8010D0] transition-colors duration-300"
            onClick={handleReservation}
          >
            Make A Reservation
          </button>
        </div>
      </div>

      {/* Right Section */}
      <div className="relative w-full lg:w-1/2 mt-8 lg:mt-0">
        <img
          src={hero1}
          alt="Hero 1"
          className="object-cover w-full max-w-md mx-auto lg:max-w-none lg:ml-auto"
        />
        <img
          src={flower}
          alt="Flower"
          className="absolute w-16 sm:w-20 md:w-24 -top-8 right-4 sm:right-8 md:right-12 lg:-top-12 lg:right-0"
        />
      </div>
    </div>
  );
};

export default HomeSectionOne;
