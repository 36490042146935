import React from "react";
import quoteSvg from '../assets/svg/quote.svg'

interface CardProps {
  name: string;
  description: string;
}

const Card: React.FC<CardProps> = ({ name, description }) => {
  return (
    <div className="bg-[#F4BAD5] rounded-lg p-4 sm:p-5 md:p-6 shadow-md flex flex-col sm:flex-row items-center sm:items-start gap-4 sm:gap-6 md:gap-8 h-auto sm:h-[200px] md:h-[220px] lg:h-[240px]">
      <img
        src={quoteSvg}
        alt=""
        className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 flex-shrink-0"
      />
      <div className="flex flex-col justify-between items-center sm:items-start gap-2 sm:gap-3 overflow-hidden h-full w-full">
        <h2 className="text-lg sm:text-xl font-bold mb-1 sm:mb-2 text-center sm:text-left text-[#A020F0] line-clamp-2 w-full">
          {name}
        </h2>
        <div className="overflow-y-auto flex-grow w-full">
          <p className="text-sm sm:text-base text-center sm:text-left text-[#A020F0] break-words whitespace-pre-wrap line-clamp-3 sm:line-clamp-4">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
