import React from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

interface BookingModalContent3Props {
  bookingDetails: {
    bookingDate: string;
    bookingTime: string;
    serviceName: string;
    styleId: number;
    styleName: string;
    styleDuration: string;
    styleDeposit: string;
    discount: number;
    value: number;
    fullName: string;
    email: string;
    mobilePhone: string;
    childName: string;
    policyAgreement: boolean;
    cancellationPolicy: boolean;
  };
}

const BookingModalContent3: React.FC<BookingModalContent3Props> = ({
  bookingDetails,
}) => {

  function formatDuration(duration: string): string {
    if (!/^\d+:\d{2}$/.test(duration)) {
      return duration; // Return original string if it doesn't match expected format
    }

    const [hours, minutes] = duration.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      return duration; // Return original string if conversion to number fails
    }

    if (hours === 0) {
      return `${minutes}mins`;
    } else {
      return `${hours}hr${hours > 1 ? 's' : ''}:${minutes}mins`;
    }
  }
  function formatTime(timeString) {
    // Check if the timeString is valid
    if (!timeString || typeof timeString !== 'string') {
      return 'Invalid Time';
    }

    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');

    // Convert hours to number for comparison
    const hoursNum = parseInt(hours, 10);

    // Determine if it's AM or PM
    const period = hoursNum >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    const formattedHours = hoursNum % 12 || 12;

    // Ensure minutes are always two digits
    const formattedMinutes = minutes.padStart(2, '0');

    // Return the formatted time string
    return `${formattedHours}:${formattedMinutes} ${period}`;
  }// Return the formatted time string

  return (
    <div className="bg-[#FADADF] p-6">
      <div className="max-w-xl mx-auto">
        
        <p className="text-center text-purple-500 p-4  font-bold">
            An SMS notification will be sent once your deposit has been paid and confirmed
          </p>

        <p className="text-justify mb-4 font-bold">
          Thank you for scheduling your hair adventure with BLHR. Your appointment has been booked. A deposit fee of $20 is required to secure your spot. Deposit not paid
          within 24 hours of booking will result in cancellation of your booking spot
        </p>
        <div className="mt-6 p-4 bg-yellow-50 rounded-lg border border-yellow-200 mb-5">
          <p className="text-base sm:text-lg md:text-xl text-gray-700 leading-relaxed">
            Kindly pay a{" "}
            <span className="font-bold text-[#A020F0]">${bookingDetails.styleDeposit}</span>{" "}
            deposit fee. Please make payment to{" "}
            <span className="font-bold">beenahairatrading@gmail.com</span>, with your child's name and hair style as remark.
          </p>
        </div>
        <div className="mb-6 bg-white p-6 rounded-lg shadow-md">
          {/* Title */}
          <h3 className="text-2xl font-extrabold text-[#A020F0] mb-4 text-center">Booking Summary</h3>

          {/* Booking Details Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {/* Date and Time of Appointment */}
            <div className="p-4 border rounded-lg shadow-sm">
              <p className="text-sm text-gray-600 font-semibold mb-1">Date of Appointment</p>
              <p className="font-semibold text-[#A020F0]">{bookingDetails.bookingDate}</p>
              <p className="text-sm text-gray-600 font-semibold mt-4 mb-1">Time of Appointment</p>
              <p className="font-semibold text-[#A020F0]">{formatTime(bookingDetails.bookingTime)}</p>
            </div>

            {/* Style and Service */}
            <div className="p-4 border rounded-lg shadow-sm">
              <p className="text-sm text-gray-600 font-semibold mb-1">Style</p>
              <p className="font-semibold text-[#A020F0]">{bookingDetails.styleName}</p>
              <p className="text-sm text-gray-600 font-semibold mt-4 mb-1">Service</p>
              <p className="font-semibold text-[#A020F0]">{bookingDetails.serviceName}</p>
            </div>

            {/* Price and Duration */}
            <div className="p-4 border rounded-lg shadow-sm sm:col-span-2">
              <div className="flex justify-between">
                <div>
                  <p className="text-sm text-gray-600 font-semibold mb-1">Price</p>
                  <p className="font-semibold text-[#A020F0]">${bookingDetails.value}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-600 font-semibold mb-1">Duration</p>
                  <p className="font-semibold text-[#A020F0]">{formatDuration(bookingDetails.styleDuration)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>



      
      </div>
    </div>
  );
};

export default BookingModalContent3;
