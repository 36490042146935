import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBookings } from '../../api';
import { RootState } from '../store/store';

export const fetchAllBookings = createAsyncThunk(
  'bookings/fetchBookings',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const token = state.user.userData?.token;
    const bookings = await getBookings(token);
    return bookings;
  }
);

export interface BookingsState {
  bookings: any[];
  status: string;
  error: string | null;
}

const initialState: BookingsState = {
  bookings: [],
  status: 'idle',
  error: null,
};

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllBookings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllBookings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookings = action.payload;
      })
      .addCase(fetchAllBookings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message || null;
      });
  },
});

export default bookingsSlice.reducer;
