import UploadTable from "./UploadTable";

const videoData = [
  {
    dateadded: "2022-04-01",
    url: "https://example.com/haircut-basics",
    videoname: "Haircut Basics",
    videolocation: "Studio A",
    status: "Active",
  },
  {
    dateadded: "2022-04-02",
    url: "https://example.com/curly-hair-care",
    videoname: "Curly Hair Care Tips",
    videolocation: "Online",
    status: "Active",
  },
  {
    dateadded: "2022-04-03",
    url: "https://example.com/dyeing-tips",
    videoname: "Pro Tips for Dyeing Your Hair",
    videolocation: "Studio B",
    status: "Active",
  },
  {
    dateadded: "2022-04-04",
    url: "https://example.com/summer-hair",
    videoname: "Summer Hair Protection",
    videolocation: "Online",
    status: "Pending",
  },
  {
    dateadded: "2022-04-05",
    url: "https://example.com/hair-growth-secrets",
    videoname: "Secrets to Faster Hair Growth",
    videolocation: "Studio A",
    status: "Active",
  },
  {
    dateadded: "2022-04-06",
    url: "https://example.com/hair-braiding-101",
    videoname: "Hair Braiding 101",
    videolocation: "Online",
    status: "Inactive",
  },
  {
    dateadded: "2022-04-07",
    url: "https://example.com/ultimate-hairstyling",
    videoname: "The Ultimate Hairstyling Guide",
    videolocation: "Studio B",
    status: "Active",
  },
  {
    dateadded: "2022-04-08",
    url: "https://example.com/winter-hair-care",
    videoname: "Winter Hair Care Strategies",
    videolocation: "Online",
    status: "Active",
  },
  {
    dateadded: "2022-04-09",
    url: "https://example.com/balayage-technique",
    videoname: "Mastering the Balayage Technique",
    videolocation: "Studio A",
    status: "Pending",
  },
  {
    dateadded: "2022-04-10",
    url: "https://example.com/mens-hair-styling",
    videoname: "Men's Hair Styling Tips",
    videolocation: "Online",
    status: "Active",
  },
  {
    dateadded: "2022-04-11",
    url: "https://example.com/natural-hair-routine",
    videoname: "Building a Natural Hair Routine",
    videolocation: "Studio B",
    status: "Active",
  },
  {
    dateadded: "2022-04-12",
    url: "https://example.com/avoiding-split-ends",
    videoname: "Avoiding Split Ends: Best Practices",
    videolocation: "Online",
    status: "Inactive",
  },
  {
    dateadded: "2022-04-13",
    url: "https://example.com/vintage-hairstyles",
    videoname: "Creating Vintage Hairstyles",
    videolocation: "Studio A",
    status: "Active",
  },
];

const headers = [
  "Date Added",
  "URL",
  "Video Name",
  "Video Location",
  "Status",
  "Actions",
];

const UploadData = () => {
  return (
    <div className="mt-5 p-6">
      <UploadTable data={videoData} headers={headers} />
    </div>
  );
};

export default UploadData;
