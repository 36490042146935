import opening from '../../../assets/svg/openImg.svg';
import heart from '../../../assets/svg/heart.svg';
import { FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';

const OpeningHours = () => {
    return (
        <div className="px-4 md:px-0 mb-8 flex flex-col items-center md:items-end  ">
            <div className='flex flex-col items-center md:flex-row justify-center md:justify-start w-full space-y-3 md:space-x-24 md:mb-3'>
                <p className="block text-gray-700 font-bold text-xl md:text-4xl mb-2">Opening Hours</p>
                <img src={opening} alt="flower" className="w-10 h-32 md:inline" />
            </div>

            <div className="mb-4 w-full">
                <p className="text-[#E20F7F] text-base md:text-xl leading-6 md:leading-10 mb-2 text-center md:text-left">Monday - Friday: 7am - 7pm</p>
                <p className="text-[#E20F7F] text-base md:text-xl leading-6 md:leading-10 mb-2 text-center md:text-left">Saturday: 11am - 7pm</p>
                <p className="text-[#E20F7F] text-base md:text-xl leading-6 md:leading-10 mb-2 text-center md:text-left">Sunday: 11am - 4pm</p>
            </div>

            <div className='flex flex-col items-center md:flex-row justify-center md:justify-start w-full space-y-3 md:space-x-10 md:mb-3'>
                <p className="block text-gray-700 font-bold text-xl md:text-4xl mb-2">Give us a Shoutout</p>
                <img src={heart} alt="flower" className="w-32 h-32 md:inline" />
            </div>

            <div className="flex items-center justify-center md:justify-start w-full md:space-x-10">
                <FaFacebook className="text-[#E20F7F] cursor-pointer hover:text-white" size={40} />
                <a href='https://www.instagram.com/blhrt24?igsh=dTlvamQ4Y2NtZHd6&utm_source=qr' target='_blank'> <FaInstagram className="text-[#E20F7F] cursor-pointer hover:text-white" size={40} /></a>
                <FaTwitter className="text-[#E20F7F] cursor-pointer hover:text-white" size={40} />
                {/* <FaLinkedinIn className="text-[#E20F7F] cursor-pointer hover:text-white" size={40} /> */}
            </div>

            <div className="mb-4 w-full">
                <p className="text-[#E20F7F] text-base md:text-xl leading-6 mt-10 mb-2 text-center md:text-left">Phone: +14036084846</p>
            </div>
        </div>
    );
};

export default OpeningHours;
