import React, { useState } from 'react';
import Calendar from '../Calendar/Calendar';
import { format, parseISO, isAfter } from 'date-fns';

interface BookedService {
  bookingDate: string;
  bookingTime: string;
  serviceName: string;
  styleId: number;
  booked: boolean;
}

interface BookingModalContent1Props {
  availableDays: string[];
  availableTimes: string[];
  bookedServices: BookedService[];
  serviceId: number;
  onDateTimeSelected: (date: string, time: string) => void;
}

const BookingModalContent1: React.FC<BookingModalContent1Props> = ({
  availableDays,
  availableTimes,
  bookedServices,
  serviceId,
  onDateTimeSelected,
}) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  const handleDateSelect = (date: Date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setSelectedDate(formattedDate);
    setSelectedTime(null);
    setSelectedDates([formattedDate]);
  };

  const handleTimeSelect = (time: string) => {
    setSelectedTime(time);
  };

  const handleNext = () => {
    if (selectedDate && selectedTime) {
      onDateTimeSelected(selectedDate, selectedTime);
    }
  };

  const isTimeBooked = (date: string, time: string) => {
    return bookedServices.some(
      (bookedService) =>
        bookedService.bookingDate === date &&
        bookedService.bookingTime === time &&
        bookedService.styleId === serviceId
    );
  };

  const isTimePassed = (time: string) => {
    const currentTime = new Date();
    const selectedDateTime = parseISO(`${selectedDate}T${time}`);
    return isAfter(currentTime, selectedDateTime);
  };

  const isDateFullyBooked = (date: string) => {
    return availableTimes.every((time) => isTimeBooked(date, time));
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2">
          <Calendar
            selectedDates={selectedDates}
            availableDays={availableDays}
            onChange={handleDateSelect}
            selectRange={false}
            minDate={new Date()}
            bookedDates={availableDays
              .filter((date) => isDateFullyBooked(date))
              .map((date) => ({ date, styleId: serviceId }))}
          />
        </div>
        <div className="md:w-1/2 md:pl-4 mt-4 md:mt-0">
          <h3 className="text-lg font-semibold mb-4">Available Times:</h3>
          <ul id="timetable" className="grid grid-cols-2 md:grid-cols-2 gap-2">
            {availableTimes.map((time) => (
              <li key={time}>
                <input
                  type="radio"
                  id={`time-${time}`}
                  value={time}
                  className="hidden peer"
                  name="timetable"
                  checked={selectedTime === time}
                  onChange={() => handleTimeSelect(time)}
                  disabled={
                    !selectedDate ||
                    isTimeBooked(selectedDate, time) ||
                    isTimePassed(time)
                  }
                />
                <label
                  htmlFor={`time-${time}`}
                  className={`inline-flex items-center justify-center w-full p-2 text-sm font-medium text-center bg-white border rounded-lg cursor-pointer ${
                    !selectedDate ||
                    isTimeBooked(selectedDate, time) ||
                    isTimePassed(time)
                      ? 'text-gray-400 border-gray-400'
                      : 'text-pink-500 border-pink-500 hover:text-white hover:bg-pink-500'
                  } ${
                    selectedTime === time
                      ? 'peer-checked:border-pink-500 peer-checked:bg-pink-500 peer-checked:text-white'
                      : ''
                  }`}
                  style={{
                    cursor:
                      !selectedDate ||
                      isTimeBooked(selectedDate, time) ||
                      isTimePassed(time)
                        ? 'not-allowed'
                        : 'pointer',
                  }}
                >
                  {time}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <button
          onClick={handleNext}
          disabled={
            !selectedDate ||
            !selectedTime ||
            isTimeBooked(selectedDate, selectedTime) ||
            isTimePassed(selectedTime)
          }
          className="w-full py-2 px-4 bg-pink-500 text-white rounded-md hover:bg-pink-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default BookingModalContent1;
