import { useEffect } from "react";
import topSvg from "../assets/svg/Vector.svg";
import Footer from "../components/Footer";
import HomeSectionFour from "./sections/home/HomeSectionFour";
import HomeSectionOne from "./sections/home/HomeSectionOne";
import HomeSectionThree from "./sections/home/HomeSectionThree";
import HomeSectionTwo from "./sections/home/HomeSectionTwo";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="relative -z-10">
        <img
          src={topSvg}
          alt="SVG Icon"
          className="absolute top-0 right-0 md:right-50 md:max-h-screen md:overflow-hidden"
          style={{ marginTop: "-355px" }}
        />
      </div>
      {/* CORNROWS */}
      <div className="">
        <HomeSectionOne />
        <HomeSectionTwo />
        <HomeSectionThree />
        {/* When Review Crud api is ready enable it, its going to be a slider */}
        <HomeSectionFour />   
      </div>

      <Footer />
    </>
  );
};

export default Home;
