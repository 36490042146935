import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import Loading from "../../../components/resuseables/Loading";
import { api } from "../../../api";

const ContactForm: React.FC = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.post(`/contact-us`, formData);
            console.log("Email sent successfully");
            toast.success("Email sent successfully!");
            setFormData({
                name: "",
                email: "",
                phone: "",
                message: "",
            });
        } catch (error) {
            console.error("Error sending email:", error);
            toast.error("Error sending email.");
        } finally {
            setLoading(false);
        }
    };

    const isFormEmpty = !formData.name || !formData.email || !formData.phone || !formData.message;

    return (
        <div className="w-full md:w-2/3 mb-8">
            <div>
                <p className="text-xl md:text-2xl  xl:text-5xl 2xl:text-9xl font-semibold text-[#FF0066] text-center xl:text-left mb-3">
                    Contact Us
                </p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-700 font-bold mb-2 text-xl md:text-2xl lg:text-3xl">
                        Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full border-b-2 border-black py-2 px-3 bg-transparent focus:outline-none text-black"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700 font-bold mb-2 text-xl md:text-2xl lg:text-3xl">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full border-b-2 border-black py-2 px-3 bg-transparent focus:outline-none text-black"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="phone" className="block text-gray-700 font-bold mb-2 text-xl md:text-2xl lg:text-3xl">
                        Phone
                    </label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full border-b-2 border-black py-2 px-3 bg-transparent focus:outline-none text-black"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="message" className="block text-gray-700 font-bold mb-2 text-xl md:text-2xl lg:text-3xl">
                        Type your message here:
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full border-b-2 border-black py-2 px-3 bg-transparent focus:outline-none text-black"
                    ></textarea>
                </div>
                {loading ? (
                    <div className="flex justify-center items-center w-full">
                        <Loading />
                    </div>
                ) : (
                    <div className="flex justify-center items-center w-full">
                        <button
                            type="submit"
                            className="bg-[#A020F0] hover:bg-indigo-700 text-white font-bold py-2 px-6 focus:outline-none 
                            focus:shadow-outline w-full md:w-1/3 text-xl uppercase rounded-full cursor-pointer"
                            disabled={isFormEmpty}
                        >
                            Submit
                        </button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default ContactForm;
