import React from 'react'
import { useLocation } from 'react-router-dom';
import ResetPasswordForm from '../components/forms/ResetPasswordForm';
import Footer from '../components/Footer';

const ResetPasswordPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    return (
        <div>
            {token ? <ResetPasswordForm token={token} /> : 'Invalid reset link'}
            <Footer />
        </div>
    );
};

export default ResetPasswordPage;