
import ReviewsTable from "./ReviewsTable"


const AddReviews = () => {
    return (
        <div><ReviewsTable /></div>
    )
}

export default AddReviews