// ScrollToTopLink.tsx
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useCurrentLocation } from '../hooks/useCurrentLocation';


interface ScrollToTopLinkProps extends LinkProps {
    children: React.ReactNode;
}

export const ScrollToTopLink: React.FC<ScrollToTopLinkProps> = ({ to, children, ...props }) => {
    const currentLocation = useCurrentLocation();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (to === currentLocation) {
            event.preventDefault();
            window.scrollTo(0, 0);
        }
    };

    return (
        <Link to={to} onClick={handleClick} {...props}>
            {children}
        </Link>
    );
};