import { ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { fetchServiceTypes } from "../redux/reducers/serviceTypeSlice";
import Loading from "./resuseables/Loading";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




interface ServiceTypeSideNavProps {
    setSelectedServiceId: (serviceId: number | null) => void;
    serviceTypes : any[];
}



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style, display: "block", background: "#FF0066",
                resizeMode: "contain",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                zIndex: 1,
                textAlign: "center",
                alignContent: "center",
                justifyContent: "center",
                color: "white",
                scale: "1.2",

            }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style, display: "block", background: "#FF0066",
                resizeMode: "contain",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                zIndex: 1,
                textAlign: "center",
                alignContent: "center",
                justifyContent: "center",
                color: "white",
                scale: "1.2",
                // marginLeft: "100px",
                // marginRight: "100px"

            }}
            onClick={onClick}
        />
    );
}

const ServiceTypeSideNav = ({ serviceTypes, setSelectedServiceId }: ServiceTypeSideNavProps) => {
    const handleServiceTypeClick = (serviceId: number) => {
        setSelectedServiceId(serviceId);
    };

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                    autoplaySpeed: 2000,
                    autoplay: true,
                    pauseOnHover: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    autoplaySpeed: 2000,
                    autoplay: true,
                    pauseOnHover: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplaySpeed: 2000,
                    autoplay: true,
                    pauseOnHover: true
                }
            }
        ]
    };

    return (
        <div className="w-2/4 mb-16 slider-container mt-8">
            <Slider {...settings}>
                {serviceTypes.map((serviceType: any) => (
                    <p
                        key={serviceType.id}
                        className="text-[#FF0066] text-md font-semibold cursor-pointer p-3 w text-center hover:bg-[#FF6699] hover:text-white rounded-lg"
                        onClick={() => handleServiceTypeClick(serviceType.serviceId)}
                    >
                        {serviceType.serviceName}
                    </p>
                ))}
            </Slider>
        </div>
    );
};

export default ServiceTypeSideNav;
