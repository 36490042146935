import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from '../../redux/store/store';

const DashboardSideBar = () => {
    const activeClassName = 'bg-brand-secondary text-white';
    return (
        <aside className="flex flex-col w-72 min-h-screen px-5 overflow-y-auto bg-white ">
            <div className="flex flex-col justify-between flex-1 mt-6">
                <nav className="-mx-3 space-y-6 ">
                    <div className="space-y-3 text-xl">
                        <NavLink
                            to="/dashboard/bookings"
                            className={({ isActive }) =>
                                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${isActive ? activeClassName : ''
                                }`
                            }
                        >
                            <span className="mx-2 font-medium">My Appointments</span>
                        </NavLink>
                        <NavLink
                            to="/dashboard/settings"
                            className={({ isActive }) =>
                                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${isActive ? activeClassName : ''
                                }`
                            }
                        >
                            <span className="mx-2 font-medium">Settings</span>
                        </NavLink>
                        <NavLink
                            to="/dashboard/profile"
                            className={({ isActive }) =>
                                `flex items-center px-3 py-2 text-brand-primary transition-colors duration-300 transform rounded-lg hover:bg-brand-secondary hover:text-white ${isActive ? activeClassName : ''
                                }`
                            }
                        >
                            <span className="mx-2 font-medium">Profile</span>
                        </NavLink>
                    </div>
                </nav>
            </div>
        </aside>
    )
}

export default DashboardSideBar;