import React, { useEffect } from 'react'
import PrivacyPolicy from '../components/Bookings/PrivacyPolicy'
import Footer from '../components/Footer'


const Policy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <PrivacyPolicy />
            <Footer />
        </>
    )
}

export default Policy