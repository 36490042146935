import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store/store';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import { updateBooking } from '../../redux/reducers/updateBookingSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { AppDispatch } from '../../redux/store/store'; // Ensure you have the proper type for your dispatch
import { toast } from 'react-toastify';
import Loading from '../resuseables/Loading';
import { useNavigate } from 'react-router-dom';
import { set } from 'date-fns';

interface RescheduleBookingModalContent2Props {
  selectedDate: string;
  selectedTime: string;
  service: {
    serviceName: string;
    styleId: number;
    styleName: string;
    style: string;
    styleDuration: string;
    styleDeposit: string;
    discount: number;
    value: number;
  };
  bookingId: number;
  onClose: () => void;
}

const RescheduleBookingModalContent2: React.FC<
  RescheduleBookingModalContent2Props
> = ({ selectedDate, selectedTime, service, bookingId, onClose }) => {
  const dispatch = useDispatch<AppDispatch>(); // Use the proper dispatch type
  const user = useSelector((state: RootState) => state.user.userData);
  const updateBookingStatus = useSelector(
    (state: RootState) => state.updateBooking.status
  );


  // Default values
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`;
  const email = user?.email || '';

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let timeoutId: NodeJS.Timeout | null = null;

    try {
      const resultAction = await dispatch(
        updateBooking({
          bookingId,
          userId: user?.id,
          serviceId: service.styleId,
          newDate: selectedDate,
          newTime: selectedTime,
        })
      );

      const updatedBooking = unwrapResult(resultAction);
      toast.success("Rescheduled booking successfully");
      onClose();

      timeoutId = setTimeout(() => {
        window.location.reload();
      }, 2000);

    } catch (error) {
      console.error('Error updating booking:', error);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }

  return (
    <div className="bg-[#F7F9F2] p-6">
      <div className="max-w-2xl mx-auto">
        <div className="flex justify-between items-center mb-3">
          {/* <MdOutlineKeyboardBackspace
            size={40}
            className="cursor-pointer"
            onClick={onClose}
          />
          <AiFillCloseCircle
            size={40}
            className="cursor-pointer"
            onClick={onClose}
          /> */}
        </div>
        <p className="text-md mb-4">
          Kindly ensure punctuality for your appointment. While there is a
          15-minute grace period, it's important not to take advantage of this
          time. If you anticipate running late, please send a courtesy text to
          notify me.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex flex-col space-y-4">
              <div className="flex space-x-4 w-96">
                <input
                  type="text"
                  name="fullName"
                  disabled
                  placeholder="Name"
                  defaultValue={fullName}
                  className="w-1/2 px-4 py-2 bg-[#FADADF] border border-[#A020F0] rounded focus:outline-none focus:ring-2 focus:ring-[#A020F0] placeholder-[#A020F0]"
                />
                <input
                  type="email"
                  name="email"
                  disabled
                  placeholder="Email"
                  defaultValue={email}
                  className="w-1/2 px-4 py-2 bg-[#FADADF] border border-[#A020F0] rounded focus:outline-none focus:ring-2 focus:ring-[#A020F0] placeholder-[#A020F0]"
                />
              </div>
            </div>
          </div>   
          <div className="flex justify-center">
            <button
              type="submit"
              className="py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white bg-pink-500 focus:outline-none focus:ring-2 focus:ring-pink-600"
              disabled={updateBookingStatus === 'loading'}
            >
              {updateBookingStatus === 'loading' ? (
                <Loading />
              ) : (
                'Reschedule Now'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RescheduleBookingModalContent2;
