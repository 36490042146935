import React from 'react';

interface BookingPolicyProps {}

const BookingPolicy = (props: BookingPolicyProps) => {
  return (
    <div className="bg-[#fadadf] py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-extrabold text-gray-900 mb-8">
            Booking Policies
          </h2>
          <div className="space-y-8">
            <div>
              <h3 className="text-xl font-semibold text-purple-600 mb-4">
                Cancellation Policy
              </h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>
                  Users can cancel appointments up to 48 hours in advance.
                </li>
                <li>
                  If it is less than 48 hours before the appointment, the
                  cancellation page will not be available.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-purple-600 mb-4">
                Re-Scheduling Policy
              </h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>
                  Users can reschedule appointments up to 48 hours in advance,
                  provided there is an available date to reschedule to.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-purple-600 mb-4">
                Payment Policy
              </h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>
                  A non-refundable deposit of $20 is required to secure the
                  appointment.
                </li>
                <li>
                  Please make Payment to beerlahairoitrading@gmail.com, with
                  your child's name and hair service as remark.
                </li>
                <li>Your appointment will be confirmed once deposit is made</li>
                <li>
                  The deposit can only be refunded in case of emergencies.
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-semibold text-purple-600 mb-4">
                Other Policies
              </h3>
              <ul className="list-disc pl-6 space-y-2 text-gray-600">
                <li>
                  Please arrive on time. A 15-minute grace period will be
                  allowed. After that, a late fee of $15 will be applied.
                </li>
                <li>
                  Hair should be loosened, properly washed, and matted into two
                  or four sections to avoid tangles that can lead to possible
                  breakage unless loosening and washing are selected as add-on
                  services.
                </li>
                <li>
                  No products should be
                  applied to the hair prior to the appointment.
                </li>
                <li>
                  Hair will be blown out by the hairstylist before braiding.
                </li>
                <li>
                  Snacks and drinks will be provided. Please notify us of any
                  allergies.
                </li>
                <li>
                  Parents should drop off only. A reminder notification will be
                  sent 20 minutes before the hair appointment is completed for
                  pick-up arrangements.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPolicy;
