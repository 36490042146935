import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateServiceInfoMain from "../../forms/UpdateServiceInfoMain";
import { api } from "../../../api";
import { fetchStyles } from "../../../redux/reducers/stylesSlice";
import { RootState } from "../../../redux/store/store";
import { useState } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useModal } from "../../ModalProvider";

const UpdateServiceInfoWrapper: React.FC = () => {
    const token = useSelector((state: RootState) => state.user.userData?.token);
    const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const style = location.state?.style;
    const { openModal, closeModal } = useModal();

    const handleUpdateStyle = async (
        styleId: number,
        updatedStyleData: FormData
    ) => {
        console.log('Received updatedStyle data:', updatedStyleData);
        try {
            const response = await api.put(
                `/api/styles/update/${styleId}`,
                updatedStyleData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if (response.status === 200) {
                // toast.success('Style updated successfully');
                dispatch(fetchStyles());
                closeModal();
            } else {
                throw new Error('Failed to update style');
            }
        } catch (error) {
            console.error('Error updating style:', error);
            // toast.error('Failed to update style. Please try again.');
        }
    };



    return (
        <UpdateServiceInfoMain
            style={style}
            onUpdate={handleUpdateStyle}
            onCancel={closeModal}
        />
    );
};

export default UpdateServiceInfoWrapper;