import React from 'react';
import cup from '../../../assets/images/value.png';
import group from '../../../assets/svg/groupframe.svg';
import target from '../../../assets/svg/target.svg';


const AboutSectionTwo = () => {
    const sections = [
        {
            imgSrc: target,
            title: 'Mission Statement',
            description: "Making Kids' Hair Fun: Our mission is to create happy hair moments for children. We strive to make haircare enjoyable and stress-free, providing safe, stylish, and playful experiences that leave kids feeling confident and excited about their hair."
        },
        {
            imgSrc: group,
            title: 'Target Audience',
            description: "Bringing Joy to Kids' Hair, Ages 4-12."
        },
        {
            imgSrc: cup,
            title: 'Core Values',
            description: "Promoting Inclusivity, Fostering Creativity, Ensuring Safety"
        }
    ];

    return (
        <div className='px-16 mt-40 mb-36'>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 mt-6 md:mt-12 w-full">
                {sections.map((section, index) => (
                    <div key={index} className="relative flex flex-col justify-center items-center group">
                        <div className="flex flex-col justify-center items-center mt-8 md:mt-0">
                            <img src={section.imgSrc} alt={section.title} className="object-cover w-24 md:w-24" />
                            <p className="text-lg md:text-2xl text-center md:text-left text-[#FF0066] leading-6 md:leading-10 mt-4 md:mt-0 ml-2">
                                {section.title}
                            </p>
                        </div>
                        <div className="absolute top-0 left-0 w-full  flex flex-col justify-center items-center bg-white p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30">
                            <img src={section.imgSrc} alt={section.title} className="object-cover w-24 md:w-24 mb-4" />
                            <p className="text-sm md:text-lg text-center text-gray-800">
                                {section.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AboutSectionTwo;
