import React from 'react';
import { CSSProperties } from 'react';
import { PropagateLoader } from 'react-spinners';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

const Loading = () => {
  return (
    <div className="flex justify-center items-center h-auto">
      <PropagateLoader
        color="#E20F7F"
        loading={true}
        cssOverride={override}
        size={15}
      />
    </div>
  );
};

export default Loading;
