import React from 'react';
import { AiFillCloseCircle } from "react-icons/ai";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-pink-500 bg-opacity-75 z-50">
            <div className="bg-white rounded-lg w-full max-w-md mx-2">
                {/* Close button */}
                <div className="flex justify-end p-2">
                    <AiFillCloseCircle
                        className="cursor-pointer"
                        size={36}
                        onClick={onClose}
                    />
                </div>

                {/* Modal content */}
                <div className="p-2">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
