/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

interface QuestionUploadsProps {}

const QuestionUploads: React.FC<QuestionUploadsProps> = () => {
  return (
    <div className="w-full px-4 sm:px-6 lg:px-8  mx-auto border-none p-4">
      <h2 className="text-xl font-semibold text-brand-primary">
        Question Uploads
      </h2>
      <form className="flex flex-col justify-between min-h-screen ">
        <div className="grid md:grid-cols-1 justify-center gap-12">
          <div className="mt-6 grid gap-4 lg:gap-6">
            <div>
              <label className="block mb-2 text-sm text-brand-primary font-medium">
                Question Text
              </label>

              <input
                type="text"
                className="py-3 px-4 block w-full border-none text-sm  text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary  focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40 "
                placeholder="How often should you wash your hair"
              />
            </div>

            <div>
              <div className="flex justify-between mb-2 bg-slate-100 rounded-lg p-2 items-center">
                <div>
                  <span className="mr-2">A.</span>
                  <span className="mr-2 text-gray-600">3 times a week</span>
                </div>

                <div className="flex justify-center space-x-1 items-center gap-2">
                  <input type="checkbox" className="mr-2" />
                  <span className="text-gray-800 text-sm">Correct answer </span>

                  <button
                    type="button"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white hover:bg-brand-secondary disabled:opacity-50 disabled:pointer-events-none "
                  >
                    Delete
                  </button>
                </div>
              </div>

              <div className="flex justify-between mb-2 bg-slate-100 rounded-lg p-2 items-center">
                <div>
                  <span className="mr-2">B.</span>
                  <span className="mr-2 text-gray-600">3 times a week</span>
                </div>

                <div className="flex justify-center space-x-1 items-center gap-2">
                  <input type="checkbox" className="mr-2" />
                  <span className="text-gray-800 text-sm">Correct answer </span>

                  <button
                    type="button"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white hover:bg-brand-secondary disabled:opacity-50 disabled:pointer-events-none "
                  >
                    Delete
                  </button>
                </div>
              </div>

              <div className="flex justify-between mb-2 bg-slate-100 rounded-lg p-2 items-center">
                <div>
                  <span className="mr-2">C.</span>
                  <span className="mr-2 text-gray-600">3 times a week</span>
                </div>

                <div className="flex justify-center space-x-1 items-center gap-2">
                  <input type="checkbox" className="mr-2" />
                  <span className="text-gray-800 text-sm">Correct answer </span>

                  <button
                    type="button"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white hover:bg-brand-secondary disabled:opacity-50 disabled:pointer-events-none "
                  >
                    Delete
                  </button>
                </div>
              </div>

              <div className="flex justify-between mb-2 bg-slate-100 rounded-lg p-2 items-center">
                <div>
                  <span className="mr-2">D.</span>
                  <span className="mr-2 text-gray-600">3 times a week</span>
                </div>

                <div className="flex justify-center space-x-1 items-center gap-2">
                  <input type="checkbox" className="mr-2" />
                  <span className="text-gray-800 text-sm">Correct answer </span>

                  <button
                    type="button"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white hover:bg-brand-secondary disabled:opacity-50 disabled:pointer-events-none "
                  >
                    Delete
                  </button>
                </div>
              </div>

              <button
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-brand-primary text-white hover:bg-brand-secondary disabled:opacity-50 disabled:pointer-events-none "
              >
                Add
              </button>
            </div>

            <div>
              <label className="block text-sm font-medium text-brand-primary">
                Question Location
              </label>

              <select
                name="HeadlineAct"
                className="mt-1.5 w-1/4 rounded-lg border-gray-300 text-gray-700 sm:text-sm py-3 px-4"
              >
                <option value="">All about hair</option>
                <option value="JM">Dummy data</option>
                <option value="JM">Dummy data</option>
                <option value="JM">Dummy data</option>
              </select>
            </div>

            <div>
              <label className="block mb-2 text-sm text-brand-primary font-medium">
                Date Added
              </label>
              <input
                type="text"
                className="py-3 px-4 block w-1/4 border-none text-sm  text-gray-700 placeholder-gray-400 bg-gray-100 border border-gray-200 rounded-lg focus:border-brand-primary  focus:ring-brand-secondary focus:outline-none focus:ring focus:ring-opacity-40 "
                placeholder="11/12/2024"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between sm:mt-6 mt-7">
          <div>
            <button
              type="button"
              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-brand-primary hover:border-brand-primary hover:text-brand-primary disabled:opacity-50 disabled:pointer-events-none0"
            >
              Delete Service
            </button>
          </div>

          <div className="inline-flex gap-4">
            <button
              type="button"
              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-brand-primary hover:border-brand-primary hover:text-brand-primary disabled:opacity-50 disabled:pointer-events-none0"
            >
              Cancel
            </button>
            <button className="px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-brand-primary rounded-lg hover:bg-brand-secondary focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuestionUploads;
