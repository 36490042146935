import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getAllUserBookings } from '../../api';
import { RootState } from '../store/store';

interface UserData {
  id: any;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  type: string;
  roles: [];
}

export interface UserState {
  userData: UserData | null;
}

const initialUserState: UserState = {
  userData: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser(state, action: PayloadAction<UserData>) {
      state.userData = action.payload;
    },
    clearUser(state) {
      state.userData = null;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const fetchAllUserBookings = createAsyncThunk(
  'userServices/fetchUserBookings',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const token = state.user.userData?.token;
    const userId = state.user.userData?.id;
    const userBookings = await getAllUserBookings(token, userId);
    return userBookings;
  }
);

export interface UserServicesState {
  userBookings: any[];
  status: string;
  error: string | null;
}

const initialUserServicesState: UserServicesState = {
  userBookings: [],
  status: 'idle',
  error: null,
};

const userServicesSlice = createSlice({
  name: 'userServices',
  initialState: initialUserServicesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUserBookings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        fetchAllUserBookings.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.status = 'succeeded';
          state.userBookings = action.payload;
        }
      )
      .addCase(fetchAllUserBookings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  user: userSlice.reducer,
  userServices: userServicesSlice.reducer,
};
