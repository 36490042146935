// updateBookingSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store/store';
import { api } from '../../api';

export interface UpdateBookingState {
  booking: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: UpdateBookingState = {
  booking: null,
  status: 'idle',
  error: null,
};

export const updateBooking = createAsyncThunk(
  'booking/updateBooking',
  async (
    { bookingId, userId, serviceId, newDate, newTime }: any,
    { getState }
  ) => {
    const state = getState() as RootState;
    const token = state.user.userData?.token;
    const response = await api.put(
      `/api/users/reschedule/${bookingId}/${userId}/${serviceId}`,
      {
        newDate,
        newTime,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  }
);

const updateBookingSlice = createSlice({
  name: 'updateBooking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateBooking.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBooking.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded';
        state.booking = action.payload;
      })
      .addCase(updateBooking.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error?.message || null;
      });
  },
});

export default updateBookingSlice.reducer;
